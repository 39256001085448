@font-face {
  font-family: "ProximaNova";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "ProximaNova-light";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Light.otf");
}

@font-face {
  font-family: "ProximaNova-bold";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Bold.otf");
}

textarea, input {
  outline: none;
}

.form-field-type {
  display: none;
}

.form-field-type-title {
  display: none;
}

#form-create > ul {
  background: #97b200;
  margin: 0;
  padding: 0;
  border: 1px solid #fff;
}

#form-create > ul::after {
  clear: both;
  content: "";
  display: table;
}

#form-create > ul li {
  margin: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

#form-create > ul a {
  display: block;
  height: 70px;
  padding-left: 70px;
  padding-top: 25px;
  text-align: left;
  width: 145px;
  margin: 0 5px 10px;
  font-weight: bold;
  background-image: url("/admin/i/addStar.png");
  background-repeat: no-repeat;
  background-position: 5px 5px;
  box-sizing: border-box;
}

#form-create > ul a span {
  color: #ffffff;
  padding-right: 7px;
}

#form-create > ul a span:before {
  content: 'Ajouter ';
}

#form-create > ul a:hover {
  color: #000;
}

#form-create > ul #input a {
  background-image: url("/admin/i/addText.png");
}

#form-create > ul #textarea a {
  background-image: url("/admin/i/addTextarea.png");
}

#form-create > ul #select a {
  background-image: url("/admin/i/addSelect.png");
}

#form-create > ul #choices a {
  background-image: url("/admin/i/addCheckbox.png");
}

#form-create > ul #radio a {
  background-image: url("/admin/i/addRadio.png");
}

.form-field {
  border: 1px solid #b4b4b4;
  padding: 0;
}

.form-field .form-group {
  padding: 0;
  border-top: 0;
}

.form-field h2 {
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  min-height: 35px;
  background-image: url("/admin/i/hachureSelected.png");
  background-color: #ff8a00;
  color: #fff;
  font-weight: normal;
  position: relative;
  text-transform: capitalize;
  font-family: "ProximaNova-light";
  line-height: 35px;
  cursor: url("/admin/i/dragdropSel.png"), move;
}

.form-field h2.separator {
  padding: 0;
  color: white;
  padding-left: 10px;
}

.form-field .tools-form {
  position: relative;
  border-bottom: 2px dashed #ff8a00;
  margin: 15px;
}

.form-field .tools-form .fileUploadDel {
  display: none;
}

.form-field .tools-form .form-group {
  padding: 10px;
  background: rgba(0, 0, 0, 0.075);
}

.form-field .tools-form .tools-module {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 0;
  margin: 0;
  background: none;
}

.form-field .tools-form .tools-module::after {
  clear: both;
  content: "";
  display: table;
}

.form-field .tools-form .tools-module ul {
  margin: 0;
  padding: 0;
}

.form-field .tools-form .tools-module li {
  float: left;
  list-style: none;
}

.form-field .tools-form .tools-module li a {
  display: block;
  text-transform: capitalize;
  height: 30px;
  padding-left: 30px;
  font-size: 10px;
  color: #fff;
  line-height: 30px;
  background: no-repeat top left;
  text-decoration: none;
  margin-right: 15px;
}

.form-field .tools-form .tools-module li.first a {
  background-image: url("/admin/i/first.png");
}

.form-field .tools-form .tools-module li.last a {
  background-image: url("/admin/i/last.png");
}

.form-field .tools-form .tools-module li.up a {
  background-image: url("/admin/i/up.png");
}

.form-field .tools-form .tools-module li.down a {
  background-image: url("/admin/i/down.png");
}

.form-field .tools-form .tools-module li.delete a {
  background-image: url("/admin/i/delete.png");
}

.choice-container input.choice-add-input {
  width: 52.8%;
  margin-left: 1%;
  float: left;
}

.choice-container button:disabled {
  opacity: 0.5;
}

.choice-container button {
  background: #97b200;
  border: none;
  color: white;
  padding: 4px;
  margin-left: 1%;
  border-bottom: 1px solid #97b200;
  width: 4.2%;
}

.choice-container ul {
  padding: 0;
  margin-left: 21%;
  width: 58%;
}

.choice-container ul::after {
  clear: both;
  content: "";
  display: table;
}

.choice-container ul li {
  list-style: none;
  margin: 0;
  width: 100%;
  padding-left: 6%;
  display: block;
  position: relative;
}

.choice-container ul li span {
  position: absolute;
  top: -1px;
  left: 0;
  background-image: url("/admin/i/dragdropSel.png");
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background-repeat: no-repeat;
  cursor: pointer;
}

.choice-container ul li span:hover {
  opacity: 1;
}

.choice-container ul li::after {
  clear: both;
  content: "";
  display: table;
}

.choice-container ul li input {
  width: 79%;
  float: left;
  margin-bottom: 1%;
  padding: 4px;
  padding-left: 5px;
  border: 1px #b4b4b4 dashed;
  border-left: 5px solid #ff8a00;
  background: #F9F9F9;
}

.choice-container ul li a {
  float: left;
  margin-left: 2%;
  border: none;
  color: white;
  padding: 4px;
  width: 1.2%;
  background-image: url("/admin/i/delete.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 16px;
}

.lang-content {
  border-top: 20px solid #fff;
}

.lang-content .input-lang-content {
  width: 58%;
  float: left;
  margin-left: 1%;
}

.lang-content .input-lang-content input {
  width: 100%;
}

.lang-content .form-group {
  border: none;
}

.lang-content ul {
  padding: 0;
  margin: 0;
}

.lang-content li {
  display: inline-block;
}

.lang-content li.active a {
  background: #ff8a00;
}

.lang-content li:hover a {
  background: #ff8a00;
}

.lang-content li a {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  background: #97b200;
  border-radius: 0;
  min-width: 80px;
  text-align: center;
  text-decoration: none !important;
  color: white;
  text-transform: uppercase;
}

.lang-content .lang-input {
  display: none;
}

.lang-content .tiny-content {
  width: 100%;
}

.lang-content .tiny-content .mce-tinymce {
  width: 100% !important;
}

.lang-content .tiny-content.lang {
  display: none;
}
