@charset "UTF-8";
@import "bourbon/bourbon";
@import "partials/sokl/default.scss";

textarea, input { outline: none; }

.form-field-type {
  display: none;
}

.form-field-type-title {
  display: none;
}


#form-create {
  > ul {
    @include clearfix;
    background: $bt-bg;
    margin:0;
    padding: 0;
    border: 1px solid #fff;
    li {
      margin: 5px;
      padding: 0;
      float: left;
      list-style: none;
    }
    a {
      display: block;
      height:70px;
      padding-left: 70px;
      padding-top: 25px;
      text-align:left;
      width: 145px;
      margin: 0 5px 10px;
      font-weight: bold;
      background-image: url("/admin/i/addStar.png");
      background-repeat: no-repeat;
      background-position: 5px 5px;
      box-sizing: border-box;
      span {
        color: #ffffff;
        padding-right: 7px;
      }
      span:before {
        content:'Ajouter ';
      }
      &:hover {
        color: #000;
      }
    }
    #input a {background-image: url("/admin/i/addText.png");}
    #textarea a {background-image: url("/admin/i/addTextarea.png");}
    #select a {background-image: url("/admin/i/addSelect.png"); }
    #choices a {background-image: url("/admin/i/addCheckbox.png"); }
    #radio a {background-image: url("/admin/i/addRadio.png"); }
  }
}
.form-field  {
  border: 1px solid $grey-light;
  padding: 0;
  .form-group {
    padding: 0;
    border-top: 0;
  }

  h2 {
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    min-height: 35px;
    background-image: url('/admin/i/hachureSelected.png');
    background-color: $lighton-color;
    color: #fff;
    font-weight: normal;
    position: relative;
    text-transform: capitalize;
    font-family: $typoLight;
    line-height: 35px;

    cursor: url("/admin/i/dragdropSel.png"), move;
    &.separator {
      padding: 0;
      color: white;
      padding-left: 10px;
    }
  }
  .tools-form {
    position: relative;
    border-bottom: 2px dashed $lighton-color;
    margin: 15px;

    .fileUploadDel {
      display: none;
    }
    .form-group {
      padding: 10px;
      background: rgba(#000, .075);
    }
    .tools-module {
      position: absolute;
      top:3px;
      right: 0;
      padding: 0;
      margin: 0;
      background: none;
      @include clearfix;
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        float: left;
        list-style: none;
        a {
          display: block;
          text-transform:capitalize;
          height:30px;
          padding-left: 30px;
          font-size:10px;
          color:#fff;
          line-height:30px;
          background:no-repeat top left;
          text-decoration:none;
          margin-right: 15px;
        }
        &.first a {
          background-image: url('/admin/i/first.png');
        }
        &.last a {
          background-image: url('/admin/i/last.png');
        }
        &.up a {
          background-image: url('/admin/i/up.png');
        }
        &.down a {
          background-image: url('/admin/i/down.png');
        }
        &.delete a {
          background-image: url('/admin/i/delete.png');
        }
      }
    }
  }
}

.choice-container {
  input.choice-add-input {
    width: 52.8%;
    margin-left: 1%;
    float: left;
  }

  button:disabled {
    opacity: 0.5;
  }
  button {
    background: #97b200;
    border: none;
    color: white;
    padding: 4px;
    margin-left: 1%;
    border-bottom: 1px solid #97b200;
    width: 4.2%;
  }
  ul {
    padding: 0;
    margin-left: 21%;
    width: 58%;
    @include clearfix();
    li {
      list-style: none;
      margin: 0;
      width: 100%;
      padding-left: 6%;
      display: block;
      position: relative;
      span {
        position: absolute;
        top: -1px;
        left: 0;
        background-image: url('/admin/i/dragdropSel.png');
        width: 30px;
        height: 30px;
        opacity: 0.5;
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
      @include clearfix();
      input {
        width: 79%;
        float: left;
        margin-bottom: 1%;
        padding: 4px;
        padding-left: 5px;
        border: 1px #b4b4b4 dashed;
        border-left: 5px solid #ff8a00;
        background: #F9F9F9;
      }

      a {
        float: left;
        margin-left: 2%;
        border: none;
        color: white;
        padding: 4px;
        width: 1.2%;
        background-image: url("/admin/i/delete.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 16px;
      }
    }
  }
}

.lang-content {
  border-top: 20px solid #fff;

  .input-lang-content {
    width: 58%;
    float: left;
    margin-left: 1%;
    input {
      width: 100%;
    }
  }
  .form-group {
    border: none;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    &.active {
      a {
        background: #ff8a00;
      }
    }
    &:hover {
      a {
        background: #ff8a00;
      }
    }
    a {
      display: block;
      box-sizing: border-box;
      padding: 10px;
      background: #97b200;
      border-radius: 0;
      min-width: 80px;
      text-align: center;
      text-decoration: none !important;
      color: white;
      text-transform: uppercase;
    }
  }

  .lang-input {
    display: none;
  }

  .tiny-content {
    width: 100%;
    .mce-tinymce {
      width: 100% !important;
    }
    &.lang {
      display: none;
    }
  }
}

