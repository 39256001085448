ul.nested {
  margin: 0;
  padding:0;
  border: solid #ddd;
  border-width: 1px;
  background: #fff;
  > li {
    border-top: 1px solid #ddd
  }
  > li:first-child {
    border:none
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    float:none;
    list-style: none;
    padding: 4px 2px 4px 4px;
    margin: 0;
    li:before {
      content: "↳";
    }
  }
  > li li {
    padding-top: 0;
    padding-left: 25px;
  }
  > li li li {
    padding-left: 40px;
  }
}