@charset "UTF-8";
@import "bourbon/bourbon";
@import "partials/sokl/default.scss";


#form_modules {
  border: 1px solid $grey-light;
  padding: 0;
  > ul {
    @include clearfix;
    background: $bt-bg;
    margin:0;
    padding: 0;
    padding-bottom: 40px;
    border: 1px solid #fff;
    li {
      margin: 5px;
      padding: 0;
      float: left;
      list-style: none;
    }
    a {
      display: block;
      height:70px;
      padding-left: 70px;
      padding-top: 25px;
      text-align:left;
      width: 145px;
      font-weight: bold;
      background-image: url("/admin/i/addStar.png");
      background-repeat: no-repeat;
      background-position: 5px 5px;
      box-sizing: border-box;
      span {
        color: #ffffff;
        padding-right: 7px;
      }
      span:before {
        content:'Ajouter ';
      }
      &:hover {
        color: #000;
      }
    }
    #ModuleVideo a {background-image: url("/admin/i/addVideo.png");}
    #ModuleAudio a {background-image: url("/admin/i/addMp3.png");}
    #ModuleEmbed a {background-image: url("/admin/i/addCode.png"); }
    #ModuleLink a {background-image: url("/admin/i/addLink.png"); }
    #ModuleFile a,
    #ModuleFileManagement a {background-image: url("/admin/i/addFile.png"); }
    #ModuleTilte a,
    #ModuleText a {background-image: url("/admin/i/addText.png"); }
    #ModuleTextimage a,
    #ModuleNlitem a{background-image: url("/admin/i/addTextimage.png"); }
    #ModuleGallery a,
    #ModuleGalleryList a,
    #ModuleImageDuo a,
    #ModuleImageTrio a,
    #ModuleImage a {background-image: url("/admin/i/addImage.png"); }
    #ModuleSearch a {background-image: url("/admin/i/addSearch.png"); }
    #ModuleInclude a {background-image: url("/admin/i/addInclude.png"); }
    #ModulePerson a,
    #ModulePeopleContact a {background-image: url("/admin/i/addPeople.png"); }

    #ModuleNetwork a {background-image: url("/admin/i/addNetwork.png"); }
    #ModulePlaylist a {background-image: url("/admin/i/addPlaylist.png"); }
    #ModuleBooklet a {background-image: url("/admin/i/addBooklet.png"); }
    #ModuleLikebox a {background-image: url("/admin/i/addLikebox.png"); }
    #ModuleTwitterbox a {background-image: url("/admin/i/addTwitterbox.png"); }
    #ModuleIcal a {background-image: url("/admin/i/addIcal.png"); }
    #ModuleForm a {background-image: url("/admin/i/addCheckbox.png"); }
  }
  .collection-container {
    margin: 15px;
  }
  .form-group {
    padding: 0;
    border-top: 0;
  }

  h2 {
    box-sizing: border-box;
    padding: 5px 5px 5px 40px;
    margin: 0;
    min-height: 35px;
    background-image: url('/admin/i/hachureSelected.png');
    background-color: $lighton-color;
    color: #fff;
    font-weight: normal;
    position: relative;
    font-family: $typoLight;
    cursor: url("/admin/i/dragdropSel.png"), move;
    .toggle {
      width: 35px;
      line-height: 35px;
      background: rgba(#000, .5);
    }
  }
  .module-item.closed
  {
    > div.form-group {
      display: none;
      &.tools-module {
        display: inherit;
      }
    }
  }
  .module-item {
    position: relative;
    border-bottom: 2px dashed $lighton-color;
    margin-bottom: 15px;
    > div {
      transition: all ease .25s;
    }
    .fileUploadDel {
      display: none;
    }
    .form-group {
      padding: 10px;
      background: rgba(#000, .075);
    }
    .tools-module {
      position: absolute;
      top:3px;
      right: 0;
      padding: 0;
      margin: 0;
      background: none;
      @include clearfix;
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        float: left;
        list-style: none;
        a {
          display: block;
          height:30px;
          padding-left: 30px;
          font-size:10px;
          color:#fff;
          line-height:30px;
          background:no-repeat top left;
          text-decoration:none;
          margin-right: 15px;
        }
        &.first a {
          background-image: url('/admin/i/first.png');
        }
        &.last a {
          background-image: url('/admin/i/last.png');
        }
        &.up a {
          background-image: url('/admin/i/up.png');
        }
        &.down a {
          background-image: url('/admin/i/down.png');
        }
        &.delete a {
          background-image: url('/admin/i/delete.png');
        }
      }
    }
  }
}
