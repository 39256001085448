.toggle {
  position: absolute;
  content: "\2304";
  top:0;
  left:0;
  min-width: 20px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: block;
  &:before {
    content: '\2304';
  }
  &:hover {
    &:before {
      content: '>';
    }
  }
}
.closed {
  .toggle {
    &:before {
      content: '>';
    }
    &:hover {
      &:before {
        content: '\2304';
      }
    }
  }
}