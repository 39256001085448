@font-face{
font-family : "ProximaNova";
src : url('/admin/fonts/Proxima-Nova/ProximaNova-Regular.otf');
}
$typo : 'ProximaNova', Helvetica, Arial, sans-serif;

@font-face{
font-family : "ProximaNova-light";
src : url('/admin/fonts/Proxima-Nova/ProximaNova-Light.otf');
}
$typoLight : 'ProximaNova-light';

@font-face{
font-family : "ProximaNova-bold";
src : url('/admin/fonts/Proxima-Nova/ProximaNova-Bold.otf');
}
$typoBold : 'ProximaNova-bold';


$black: #000;
$white: #fff;
$grey : #6d6d6d;
$grey-light : #b4b4b4;

$title-color : #595959;
$body-color : #000;
$body-bg : #fff;
$lighton-over-color : #7d0606;
$lighton-color : #ff8a00;
$lightoff-color : #feb734;
$link-color : $grey;
$filter-label : $black;
$filter-input : $grey;
$td-bg : $white;
$td-bg2 : #f2f2f1;
$td : $black;
$th-bg : #090909;
$th : $white;
$bt : $white;
$bt-bg : #97b200;
$bt-border: 3px double #6E9C00;
$bt-radius: 0;
$bt-over : $white;
$bt-bg-over : #a2d122;
$bt-cancel : $white; 
$bt-cancel-bg : #999;
$bt-cancel-border: 3px double #666; 
$bt-cancel-radius: $bt-radius;
$bt-del : $white;
$bt-del-bg : #f90;
$bt-del-border: 3px double #c60; 
$bt-del-radius: $bt-radius;

$module-title : #fff;
$module-bg: $lightoff-color;
$module-actions: $bt-bg;
$status-publish: #97b00a;
$status-draft: #f72271;
$status-archive: #f72271;
$header-bg: #090909;
$header: $white;
$nav : $white;
$nav-bg: transparent;
$nav2-bg: $lighton-color;
$nav2 : $white;
$nav3-bg: $white;
$nav3 : $black;
$nav-admin-color : #fff;
$nav-admin-color-bg : rgba(0, 0, 0, 0.75);
$nav-admin-color-bg-hover : rgba(0, 0, 0, 0.9);
$crop-bg: #222;
$info: #00a5cc;
$info-bg: $white;
$success : $info;
$success-bg: $info-bg;
$error : #f6005b;
$error-bg: $white;
