@charset "UTF-8";
@font-face {
  font-family: "ProximaNova";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "ProximaNova-light";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Light.otf");
}

@font-face {
  font-family: "ProximaNova-bold";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Bold.otf");
}

.center {
  text-align: center;
}

ol {
  padding: 10px;
  margin: 0;
}

caption {
  padding: 4px;
}

/* Common */
#content .module_edit h3 {
  margin: 1em 0 0 0;
  background-image: none;
  background-color: #feb734;
  line-height: 2.25em;
  padding-left: 1em;
}

.module_unlink,
#content .module_edit h3,
#content .moduleEditOrder a span {
  color: #fff !important;
}

/* Login */
.loginFormArea {
  padding: 0 20px;
  margin: 10px 0;
}

.loginFormArea .introLogin {
  font-size: 19px;
  color: #6d6d6d;
  font-weight: 300;
  text-align: center;
}

.loginFormArea form {
  text-align: center;
  width: 400px;
  margin: 0 auto;
}

.loginFormArea p.plogin {
  margin: 10px 0;
}

.loginFormArea p.ppassword {
  margin: 0 0 2px;
}

.loginFormArea p.optin {
  background: rgba(0, 0, 0, 0.025);
  margin: 0 0 10px;
  padding: 5px 15px;
  text-align: left;
  font-size: 0.85em;
}

.loginFormArea p.optin input {
  border: 1px solid #6d6d6d;
}

.loginFormArea p.optin label {
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 300;
}

.loginFormArea label[for="username"],
.loginFormArea label[for="password"] {
  display: none;
}

.loginFormArea input.txt {
  border: 1px solid #6d6d6d;
  width: 100%;
  padding: 5px 10px;
  font-size: 1.5em;
  box-sizing: border-box;
}

.loginFormArea .ppassword .lost {
  font-size: 0.85em;
  display: none;
}

.loginFormArea .submit {
  height: 88px;
  margin: 0 auto;
  box-sizing: border-box;
}

.loginFormArea .btLogin {
  width: 100%;
  padding: 15px 0;
  background: #6d6d6d;
  color: #fff;
  font-size: 2em;
  line-height: 1em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
}

.date td a {
  display: inline-block;
  padding: 3px 8px;
}

.date th, .date td {
  text-align: center;
  padding: 3px;
}

#itemByPage {
  border: solid 1px #b4b4b4;
  color: #6d6d6d;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: "ProximaNova-bold";
}

a:hover {
  text-decoration: none;
}

a.edit, a.delete {
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position: left center;
}

/* Table styling */
table {
  width: 100%;
}

tr {
  border-top: 6px;
}

th, td {
  padding: 0px 15px;
  text-align: left;
  vertical-align: top;
}

/* Header */
#header {
  background: #090909;
}

#header .spaces {
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  padding: 5px 5px 5px 15px;
  margin-right: 3%;
  background-color: #ff8a00;
}

#header .spaces select {
  border: none;
  background-color: rgba(255, 255, 255, 0.33);
}

#header .spaces select option {
  border: solid #fff;
  border-width: 0 0 1px;
}

#head {
  border: 0;
  margin: 0;
  color: #fff;
}

#head a {
  display: block;
  margin-top: 10px;
}

#welcome {
  position: absolute;
  right: 0;
  top: 15px;
  margin-right: 3%;
  width: 10%;
}

#welcome .dayname {
  text-transform: capitalize;
}

#welcome ul {
  padding: 0;
  margin: 0;
}

#welcome li {
  list-style: none;
  display: block;
  text-align: right;
  padding: 0;
  margin: 3px 0;
}

#welcome li.userName {
  font-size: 20px;
}

#welcome li .name {
  display: none;
}

#welcome li.date {
  font-family: "ProximaNova-light";
  font-size: 16px;
  color: #fff;
}

#welcome li.date .year {
  display: none;
}

#welcome li.logout a {
  color: #fff;
  font-family: "ProximaNova-bold";
  display: block;
  float: right;
}

/* Nagivation */
.navigation {
  margin: 0 auto 0 auto;
  padding: 0;
  width: auto;
}

.navigation ul {
  margin: 0;
  padding: 0;
}

.navigation > ul > li {
  position: relative;
  display: inline-block;
}

#navigation > ul > li {
  float: none;
}

.navigation li.sepa {
  width: 5px;
  display: inline-block;
}

.navigation2 > ul > li a {
  display: inline;
}

.navigation.navigation3 {
  background-color: #fff;
}

.navigation.navigation3 > ul > li a {
  color: #000;
}

#foot {
  width: 92%;
  padding: 5px 20px;
  margin: 0 auto 10px;
  border-bottom-right-radius: 10px;
}

.navigation2 a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Forms */
small {
  font-size: 12px;
  font-weight: normal;
  padding-left: 10px;
}

input, textarea, select {
  border: 1px solid;
  font-family: inherit;
  font-size: inherit;
  padding: 4px;
  max-width: 100%;
}

select {
  padding-top: 3px;
  padding-bottom: 3px;
}

input.file {
  padding: 4px 0;
}

input[type="submit"], input[type="reset"] {
  cursor: pointer;
}

/* Error and Success messages */
.error, .success {
  padding: 4px 8px;
}

span.msg {
  display: block;
  margin: 0.33em 0;
  padding: 5px;
  border-top: 1px solid #fff;
}

span.msg:first-child {
  border: none;
}

/* Pagination */
.pagination {
  text-align: right;
}

.pagination a, .pagination span {
  display: inline-block;
  margin: 0 2px;
  padding: 2px 6px;
}

/* CSS Document */
body {
  margin: 0;
  padding: 0;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

h2 {
  font-weight: normal;
  font-size: 1.3em;
  padding: 0;
  margin-bottom: 0.25em;
}

h2.pageTitle {
  color: #595959;
  font-weight: normal;
  position: relative;
}

h2.pageTitle span {
  display: block;
}

h2.pageTitle a.edit {
  font-size: 16px;
  letter-spacing: 0;
  text-decoration: underline;
}

h2.pageTitle a.edit {
  font-size: 18px !important;
}

th {
  font-weight: normal;
}

img {
  border: none;
}

.sep, .cb {
  clear: both;
  display: block;
}

em.empy {
  color: #b4b4b4;
}

body {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  color: #000;
  background: #fff;
  font-size: 14px;
}

a {
  color: #6d6d6d;
  text-decoration: none;
  outline-style: none;
}

a:hover {
  color: #ff8a00;
}

.page-wrap {
  position: relative;
  min-width: 800px;
}

input, select, textarea {
  border: none;
}

/*
---------------------
MSG
---------------------
*/
.msg p {
  margin: 0 0 0.5em;
}

.msg div {
  margin-bottom: 10px;
  padding: 5px 10px;
  color: #fff;
  border: 1px solid;
}

.msg div.wrong {
  background: #f6005b;
}

.msg div.ok {
  background: #7EBA3F;
  border-color: #468106;
}

.msg div.ok p, #bloc .msg div.ok p, .msg div.wrong p, #bloc .msg div.wrong p {
  color: #fff;
}

.msg div h5 {
  font-size: 1.25em;
  padding: 0;
  margin: 0 0 0.25em;
}

.msg div p a {
  color: #113;
}

.messageWrapper {
  margin-bottom: 10px;
}

.messageWrapper .message {
  transition: all ease 2s;
}

p.success, p.error, p.warning {
  display: block;
}

p.success, p.error, p.warning {
  margin: 1px 0;
  padding: 5px 3%;
}

p.error {
  color: #f6005b;
  background-color: #fff;
  text-align: center;
}

p.warning {
  color: #fff;
}

p.success {
  text-align: center;
  text-transform: uppercase;
  color: #00a5cc;
  background-color: #fff;
  font-weight: bold;
}

p.success a {
  color: #00a5cc;
}

div.avert,
p.info {
  text-transform: uppercase;
  color: #00a5cc;
  background-color: #fff;
  font-weight: bold;
  padding: 8px;
  margin: 20px 0;
}

#navigation li#nav_menuhome a {
  background-image: url("/admin/i/home.gif");
  background-position: center -25px;
  background-repeat: no-repeat;
  width: 20px;
}

#navigation li#nav_menuhome a span {
  visibility: hidden;
}

#footer {
  text-align: center;
  padding: 15px 0 95px;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.1);
}

#footer p {
  margin: 0;
}

.pBox #footer p {
  display: none;
}

#footer a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.2);
}

#footer a:hover {
  text-decoration: none;
}

.navigation.navigationAdmin {
  background-color: rgba(0, 0, 0, 0.75);
}

.navigation.navigationAdmin:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.navigationAdmin .userName {
  padding-right: 5px;
  display: inline-block;
}

.navigationAdmin .userName .name {
  text-transform: uppercase;
}

.navnested2 {
  display: inline;
}

.navnested2 ul {
  display: inline;
  margin: 0;
  padding: 0;
}

.navnested2 ul li {
  display: inline;
  margin: 0;
  padding: 0;
}

#bottom {
  text-align: center;
}

.bt {
  cursor: pointer;
}

.btsend, .btform {
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.msg .btsend, .msg .btform {
  margin: 5px;
  color: #404040;
}

.btsend.submit_code span,
.btsend.submit_see span {
  font-weight: normal;
  color: #6d6d6d !important;
}

a.btsend.submit_code:hover span,
a.btsend.submit_see:hover span {
  color: #ff8a00 !important;
}

.pGallerycat .actionadd a,
a.bt,
label.bt {
  color: #fff !important;
  display: block;
  padding: 2px 6px;
  margin: 2px 6px 2px 0;
  background: #97b200;
  border: 3px double #6E9C00;
  border-radius: 0;
  min-width: 180px;
  text-align: center;
  text-decoration: none !important;
}

a.btCancel,
label.btCancel {
  color: #fff;
  background-color: #999;
  border: 3px double #666;
  border-radius: 0;
}

a.btDelete,
label.btDelete {
  color: #fff;
  background-color: #f90;
  border: 3px double #c60;
  border-radius: 0;
  display: inline-block;
}

#wrap {
  margin: 0;
  opacity: 1;
  transition: all ease 2s;
}

#wrap.on {
  opacity: 0;
}

#wrap .wrap-content {
  padding: 10px 30px;
  background: #f5f5f5;
}

#wrap .wrap-content h1 {
  padding: 0 0 0.1em 0;
  margin: 1em 0 0 0;
  color: #402B0D;
  border-bottom: 1px solid #402B0D;
  font-size: 1.8em;
}

#wrap .wrap-content th, #wrap .wrap-content td {
  padding: 3px 12px;
  border: 1px solid #fff;
  background: #e2e2e2;
}

#wrap .wrap-content th {
  font-weight: bold;
}

#filter h2 {
  color: #6d6d6d;
  padding: 0;
  margin: 0 4px 2px;
  font-size: 1em;
  font-weight: bold;
  display: none;
}

#filter form > ul {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
}

#filter form > ul > li {
  display: block;
  float: left;
  margin: 0 5px 5px 0;
  padding: 0;
  list-style: none;
}

#filter .filter_reset {
  white-space: nowrap;
  font-weight: bold;
  color: #f6005b;
  margin: 17px 0 0 10px;
}

#filter .filter_reset:before {
  content: '✖ ';
  font-size: 19px;
}

#filter .filter_reset a {
  color: #f6005b;
  font-size: 0.9em;
  font-weight: normal;
  position: relative;
  bottom: 2px;
}

#filter li.filter_sel > span > label {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
}

#filter li.filter_sel > span {
  display: inline-block;
  font-size: 0.85em;
  padding: 3px;
  color: #663;
  margin-bottom: 3px;
  min-height: 40px;
}

#filter li.filter_sel select {
  min-width: 160px;
  margin: 0;
}

#filter li.filter_text > span > label {
  display: block;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
}

#filter li.filter_text > span {
  display: inline-block;
  font-size: 0.85em;
  padding: 3px;
  color: #663;
  margin-bottom: 3px;
  min-height: 40px;
}

#filter li.filter_text > span > input {
  padding: 4px 4px;
  margin: 0;
  width: 160px;
  color: #6d6d6d;
}

#filter li button {
  display: inline-block;
  background-color: #97b200;
  color: #fff;
  padding: 4px;
  cursor: pointer;
  border: none;
}

#filter li.submit {
  margin: 13px 5px 5px 0;
}

#filter li#filter_li_fullname > span > input {
  padding-left: 20px;
  background: #fff url(/admin/i/search.gif) no-repeat 1px center !important;
}

.in ul label {
  color: #000;
}

.in ul select {
  color: #6d6d6d;
}

#filter_button {
  padding: 4px 0;
}

#filter_button ul {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
}

#filter_button ul li.filterButtonTitle span,
#filter_button ul li.filterButtonEnd span {
  display: block;
  padding: 4px 8px;
  color: #999;
  background: #eee;
  border: 1px solid #eee;
  border-radius: 8px 0 0 8px;
}

#filter_button ul li.filterButtonEnd span {
  border-radius: 0 8px 8px 0;
}

#filter_button ul li.filterButtonTitle label {
  margin: 0;
  padding: 0;
}

#filter_button li {
  display: block;
  margin: 0 6px 0 0;
  padding: 0;
  float: left;
}

#filter_button li a {
  display: block;
  padding: 4px 8px;
  color: #fff;
  background: #ff8a00;
  border: 1px solid #ff8a00;
}

#filter_button li.sel a,
#filter_button li.sel a:hover {
  background: #fff;
  color: #069;
  text-decoration: none;
  border: 1px solid #069;
}

#filter_button li a:hover {
  background: #59cbfd;
  color: #fff;
  text-decoration: none;
}

.adminSearch {
  background: #FFFFCC;
  border: 1px solid #feb734;
  padding: 4px;
  margin: 2px 0;
}

.adminSearch h2 {
  color: #C19C00;
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}

.adminSearch label {
  float: left;
  padding: 2px;
  display: block;
  text-transform: capitalize;
}

.adminSearch span input {
  width: 200px;
}

.adminSearch span {
  display: inline-block;
  background-color: #ffa;
  border: 1px solid #cc6;
  border-radius: 3px;
  font-size: 0.85em;
  padding: 3px;
  color: #663;
  margin-right: 4px;
}

.adminSearch span input {
  height: 12px;
  margin: 0 3px;
}

.adminSearch span a {
  display: inline-block;
  background-color: #C19C00;
  border-radius: 3px;
  color: #fff;
  padding: 3px;
}

.listIsEmpty strong {
  font-weight: bold;
  margin: 1em 0;
  display: block;
  font-size: 1.25em;
}

.admintblist #listForm {
  overflow: auto;
}

.admintblist td span.protected,
span.protected {
  display: inline-block;
  width: 20px;
  height: 17px;
  background: url(/admin/i/lock.gif) no-repeat center top;
}

.admintblist td a.OnOff {
  display: block;
  line-height: 17px;
  background: no-repeat left top;
  padding-left: 20px;
}

.admintblist td a.OnOff0,
.admintblist td a.OnOffno,
.admintblist td a.OnOff1:hover,
.admintblist td a.OnOffyes:hover {
  background-image: url(/admin/i/off.gif);
}

.admintblist td a.OnOff1,
.admintblist td a.OnOffyes,
.admintblist td a.OnOff0:hover,
.admintblist td a.OnOffno:hover {
  background-image: url(/admin/i/on.gif);
}

.admintblist td a.OnOff:hover {
  text-decoration: none;
}

.admintblist th {
  font-family: "ProximaNova-bold";
  color: #fff;
  background: #090909;
  padding: 4px 10px;
}

.admintblist td {
  color: #000;
  padding: 5px 10px;
}

.admintblist th a {
  color: white;
  font-family: "ProximaNova-bold";
  color: #fff;
}

.admintblist td.cell1 {
  background: #fff;
}

.admintblist td.cell2 {
  background: #f2f2f1;
}

.admintblist th.selected {
  background: rgba(9, 9, 9, 0.66);
}

.admintblist th.sort-ASC a:before {
  content: '↓';
}

.admintblist th.sort-DESC a:before {
  content: '↑';
}

.admintblist td.selected1 {
  background: rgba(255, 255, 255, 0.66);
}

.admintblist td.selected2 {
  background: rgba(242, 242, 241, 0.66);
}

.admintblist .action.deactivate {
  display: none;
}

.admintblist td img {
  max-height: 90px;
}

.firstline {
  border-bottom: solid 10px white;
}

.lastline {
  border-bottom: solid 10px white;
}

.firstline th {
  border: 1px solid white;
}

.sStatusPublish {
  color: #97b00a;
}

.sStatusDraft {
  color: #f72271;
}

.sStatusArchive {
  color: #f72271;
}

.editTable {
  width: 100%;
}

.editTable > tbody > tr > td, .editTable > tbody > tr > th {
  border-top: 1px solid #fff;
  padding: 6px 4px;
  font-size: 12px;
}

.editTable td.label {
  width: 20%;
  vertical-align: top;
}

.editTable td.label .legend {
  display: block;
  font-size: 10px;
  color: #666666;
  padding-top: 2px;
}

.editTable td .fieldname {
  font-size: 10px;
  color: #666666;
  padding-top: 2px;
  font-style: normal;
  display: none;
  padding: 1px 3px;
  background: #fff;
  border-radius: 3px;
  margin-left: 3px;
}

.pGod .editTable td .show .fieldname {
  display: inline-block;
}

.editTable td.inputLong input.text, .editTable td.inputLong select, .editTable td.inputLong textarea, .editTable td textarea {
  width: 98%;
}

.editTable td textarea {
  width: 98%;
  min-height: 90px;
}

.editTable td.inputLong input.text, .editTable td.inputLong select, .editTable td.input inputnput.text, .editTable td textarea {
  width: 97%;
  border: none;
  background: #fff;
  padding: 5px 0;
  padding-left: 1%;
}

.editTable td.inputLong input.text.disabled, .editTable td.input inputnput.text.disabled {
  background: #e5e5e5;
}

.editTable td.inputLong .tiny-zone {
  width: 98%;
}

#form_group_alias label,
.form-group-classname label {
  opacity: .5;
}

#form_group_alias input,
.form-group-classname input {
  background: #eee;
  color: #888;
}

.editTable td.input select {
  border: 1px solid #999;
  background: #F6FFD4;
}

.editTable td.input input.medium {
  width: 50%;
}

.editTable td.info {
  color: #666;
  font-size: 11px;
  width: 30%;
  vertical-align: top;
}

td.inputDouble {
  background-image: none !important;
  padding-top: 0 !important;
}

.editTable tr.line1 .inputDouble,
.editTable tr.line2 .inputDouble {
  padding: 0;
}

.inputDouble .editTable tr.line1 td.label,
.inputDouble .editTable tr.line2 td.label {
  background-image: none !important;
}

.editInPlace {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom-color: #ddd;
  padding: 3px;
  line-height: 1em;
  position: relative;
  margin-right: 5px;
}

.editInPlace:hover {
  background: #fff;
  border-color: #ff8a00;
}

.editInPlace a.btEditInPlace {
  display: inline-block;
  padding: 5px;
  margin-left: 2px;
}

.editInPlace a.btEditCancel {
  display: inline-block;
  border: 0;
  padding: 5px;
  text-align: center;
  color: #fff;
  background-color: #999;
  border: 3px double #666;
  border-radius: 0;
  box-sizing: border-box;
}

.fileUploadDel input {
  visibility: hidden;
  position: absolute;
  left: -50px;
}

.fileUploadDel.sel label {
  opacity: 0.5;
}

.fileUploadDel label em {
  display: none;
}

.fileUploadDel.sel label em {
  display: block;
  font-size: 0.85em;
}

.fileUpload p.imageSize {
  margin: 0;
  padding: 0;
  font-size: 0.85em;
}

.fileUpload p.imageSize em,
.fileUpload p.imageSize strong {
  font-weight: normal;
  font-style: normal;
}

.fileUpload img {
  max-height: 120px;
  box-shadow: 0 2px 5px #999;
}

.currentFile {
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  margin: 0 0 5px;
  clear: both;
  margin-left: 21%;
  max-width: 49%;
}

.currentFile a {
  text-decoration: underline;
}

.fileUpload,
.fileUploadDel {
  margin-left: 21%;
}

.listIsEmpty .btformAdd {
  display: block;
  width: 70px;
  text-align: center;
}

/*
-----------------------------
POPUP Mode
-----------------------------
*/
body.pPopup h1#head {
  display: none;
}

body.pPopup #footer {
  display: none;
}

body.pPopup #content {
  padding: 10px;
  margin: 10px;
  width: auto;
}

body.pPopup h2.pageTitle {
  display: none;
}

/*
-----------------------------
VIEW MODE
-----------------------------
*/
body.modeView input.file,
body.modeView div.fileStyle,
body.modeView .tableFileUploadDel,
body.modeView .tableFileUpload .imageSize,
body.modeView .browseTable label,
body.modeView .browseTable input,
body.modeView .browseTable input,
body.modeView .editTable td.info,
body.modeView .ui-selectmenu,
body.modeView .module_add,
body.modeView .moduleEditOrder,
body.modeView .module_unlink_container {
  display: none;
}

.valToText {
  color: #222;
  display: block;
}

.valToText_inputtext,
.valToText_select {
  font-weight: bold;
}

.valToText .empy {
  font-weight: normal;
}

.valToText_textarea {
  white-space: pre-wrap;
}

body.modeView .buttons .btform,
body.modeView .buttons .btsend {
  display: none;
}

body.modeView .buttons .btform.btformback {
  display: block;
}

body.modeView .buttons .btform.btformprint {
  display: block;
}

body.modeView .buttons .btform.btformedit {
  display: block;
}

body.modeView .buttons .btsend.btformedit {
  display: block;
}

#previewDiv {
  background: #fff;
  border: 2px dotted #999;
  margin: 8px;
}

#bottom {
  padding: 4px;
  color: #fff;
  position: relative;
}

#bottom p {
  padding: 0;
  margin: 0;
}

#bottom p a {
  text-decoration: none;
}

#bottom-context {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 2% 0 0;
  text-transform: uppercase;
  color: #CF892D;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.searchblock {
  border: 2px solid #91E800;
  padding: 2px;
  margin-bottom: 2px;
  background: #D1FFBE;
}

.searchblock a {
  color: #0033CC;
}

.searchline {
  border-top: 1px solid #91E800;
  padding: 4px 4px 8px;
  clear: both;
}

.searchline h4 {
  font-size: 11px;
  padding: 0;
  margin: 0;
  width: 150px;
}

.searchline input, .searchline select {
  margin: 0;
}

.searchline span {
  display: block;
  padding-bottom: 2px;
}

.searchline span select {
  width: 80px;
  font-size: 10px;
}

.searchline .field {
  float: left;
  padding: 2px 8px 2px 2px;
}

.searchline .field_ {
  float: left;
  padding: 2px 8px 2px 2px;
}

.searchline .field_ label {
  color: #999;
}

.searchline .field input.text {
  background: #ffc;
  border: 1px solid #666;
}

.searchline .field_ input.text {
  background: #D1FFBE;
  border: 1px solid #999;
}

.searchline .cboolean, .searchline .ctriple {
  width: 200px;
  height: 60px;
}

.searchline .field_, .searchline .field {
  border: 1px solid #fff;
}

.searchline .field, .searchline .field_ {
  margin: 5px 5px 0 0;
}

.searchline .level {
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  padding-left: 40px;
  clear: both;
}

.searchline .level_ {
  display: none;
}

.searchline em {
  display: block;
  font-size: 10px;
  color: #003300;
  white-space: normal;
}

.query-save {
  border-top: 4px solid #666633;
  border-bottom: 4px solid #666633;
  background-color: #FFFF99;
  padding: 8px;
}

.query-save h2 {
  margin: 0;
  padding: 0 0 5px;
  color: #666633;
}

.query-save input.text {
  width: 340px;
  border: 1px solid #999;
  background: #F6FFD4;
  padding: 5px 3px;
}

.editTable .tagAdd {
  padding-bottom: 8px;
  border-bottom: 2px dotted #999;
}

.editTable .tagAdd strong {
  display: block;
}

.editTable .inputLong .tagAdd input.text,
.editTable .input .tagAdd input.text {
  width: 250px;
  margin-right: 10px;
}

.editTable .addlist_tag {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
}

.editTable .addlist_tag li {
  margin: 0;
  padding: 0;
}

.editTable .addlist_tag li label {
  display: inline;
}

.editTable .addlist_tag li.sepaddlist {
  border-top: 1px solid #999;
  padding-top: 5px;
  margin-top: 5px;
}

.avert li {
  font-size: 12px;
}

.update h2, .avert h2, .update h5, .avert h5 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: bold;
}

.update a,
.avert a.bt {
  background: #97b200;
  color: #fff;
  display: inline-block;
}

.avert a {
  color: #fff;
}

.editTable #id_addlist_prx_articles2tag {
  max-height: 400px;
  overflow: auto;
}

.editTable #id_addlist_prx_articles2tag li {
  display: block;
  margin: 0;
  padding: 0;
  float: left;
  width: 160px;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
}

p.withSelected {
  padding-left: 30px;
  background: url(/admin/i/withselected.gif) 8px 0 no-repeat;
  margin-top: 5px;
  clear: both;
  display: none;
}

.progress {
  display: block;
  background: #fff;
  border: #b4b4b4 1px solid;
  height: 10px;
  width: 90%;
  display: block;
  margin: 5px 0;
}

.progress span {
  display: block;
  height: 10px;
  background: #6d6d6d url(/admin/i/loading.gif);
  width: 0;
}

.progress span em {
  display: none;
}

.adminPagesList {
  margin: 15px 0 10px;
}

.adminPagesList ul.pagination {
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.adminPagesList ul.pagination li {
  display: inline-block;
  list-style: none;
}

.adminPagesList ul.pagination li.page:before {
  content: "•";
}

.adminPagesList ul.pagination li.page1:before {
  content: "";
}

.adminPagesList ul.pagination li.disable {
  opacity: .5;
}

.adminPagesList ul.pagination li a {
  color: #ff8a00;
}

.adminPagesList p.totals {
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  color: #b4b4b4;
}

/*
---------------------
MARK
---------------------
*/
.starsMark {
  width: 132px;
}

span.starsMark {
  background-position: 18px -1px !important;
}

span.stars_unset {
  background: url(/admin/i/stars0.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_0 {
  background: url(/admin/i/stars0.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_1 {
  background: url(/admin/i/stars1.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_2 {
  background: url(/admin/i/stars2.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_3 {
  background: url(/admin/i/stars3.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_4 {
  background: url(/admin/i/stars4.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_5 {
  background: url(/admin/i/stars5.png) no-repeat 0 -1px;
  display: block;
  width: 132px;
  height: 20px;
}

span.stars_0 em.stsZero {
  background: #feb734;
}

em.stsZero {
  text-align: center;
  font-style: normal;
  border-radius: 8px;
  height: 18px !important;
  margin-top: 1px;
}

em.stsReset {
  text-align: center;
  font-style: normal;
  border-radius: 8px;
  height: 18px !important;
  margin-top: 1px;
}

/*
---------------------
GMAP
---------------------
*/
.gMap {
  height: 375px;
}

.gmapButtons a,
.editTable td .gmapButtons a {
  text-decoration: none;
  text-align: center;
  color: #fff;
  background: #97b200;
  border: 3px double #6E9C00;
  border-radius: 0;
}

/*
---------------------
LANG
---------------------
*/
.tabLang .sel {
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #fff;
}

.tabLang {
  height: 21px;
  margin: 0;
  padding: 0 0 0 5px;
  border-bottom: 1px solid #b4b4b4;
}

.tabLang li {
  height: 20px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  float: left;
  border: 1px solid #b4b4b4;
  margin: 0 3px 0 0;
  padding: 0 8px;
  list-style: none;
  cursor: pointer;
}

.panelLang {
  background: #fff;
  padding: 5px;
  border-color: #b4b4b4;
  border-style: solid;
  border-width: 0 1px 1px 1px;
}

.ui-button.ui-state-default .ui-icon-customcheck {
  background-image: url(/admin/i/customcheckbox_.gif);
  font-weight: normal !important;
}

.ui-button.ui-state-default .ui-button-text {
  font-weight: normal !important;
}

.tableFileUploadDel .ui-button.ui-state-default .ui-button-text {
  color: #b4b4b4;
  font-size: 12px;
}

.tableFileUploadDel .ui-button.ui-state-default.ui-state-active .ui-button-text {
  color: #333;
}

.ui-button.ui-state-default.ui-state-active .ui-icon-customcheck {
  background-image: url(/admin/i/customcheckbox.gif);
}

.ui-selectmenu .ui-selectmenu-icon {
  border-left: 1px solid #ccc;
  height: 20px;
  width: 20px;
}

.ui-selectmenu,
.ui-selectmenu-menu {
  font-size: 12px !important;
}

.ui-selectmenu {
  padding-right: 20px;
}

.ui-selectmenu-menu li a,
.ui-selectmenu-status {
  padding: 0.205em 2.1em 0.205em 1em !important;
}

.ui-selectmenu {
  height: auto !important;
}

.ui-selectmenu-dropdown .ui-selectmenu-icon {
  background-image: url(/admin/i/customselect.gif) !important;
  background-position: 2px 2px !important;
}

.ui-selectmenu-dropdown.ui-state-active .ui-selectmenu-icon,
.ui-selectmenu-dropdown.ui-state-hover .ui-selectmenu-icon {
  background-image: url(/admin/i/customselect_.gif) !important;
}

.ui-selectmenu-open {
  z-index: 3000 !important;
}

.ui-state-active {
  font-weight: inherit;
}

.ui-state-active a {
  color: inherit !important;
}

.navigation {
  background: transparent;
}

.navigation2 {
  background: #ff8a00;
}

.navigation3 {
  background: #fff;
}

.navigation li a {
  color: #fff;
}

.navigation1 > ul > li > a:hover,
.navigation1 > ul > li.sel > a,
.navigation1 > ul > li.active > a {
  background-color: #ff8a00;
  color: #7d0606;
}

.navigation2 > ul > li > a:hover,
.navigation2 > ul > li.sel > a,
.navigation2 > ul > li.active > a {
  background-color: #ff8a00;
  color: #7d0606;
}

.navigation.navigation3 > ul > li.sel a,
.navigation.navigation3 > ul > li.active a {
  color: #ff8a00;
}

#navigationAdmin > ul > li a:hover,
#navigationAdmin > ul > li.sel a,
#navigationAdmin > ul > li.active a {
  color: #ff8a00;
}

/* Mosaic */
.mosaic .admintblist {
  text-align: center;
}

.mosaic .firstline,
.mosaic .mosaic .lastline {
  display: none;
}

.mosaic blockquote {
  border: 1px solid #b4b4b4;
  vertical-align: top;
  display: inline-block;
  width: 170px;
  margin: 0 8px 16px;
  overflow: hidden;
  position: relative;
  min-height: 145px;
  text-align: left;
  text-align: center;
  /*
    .actions {
      background: $white;
      margin: 0 auto;
      height: 29px;
      position: absolute;
      z-index: 1;
    }
    .actions > span {
      float:left;
      width:25px;
      margin-top:2px;
      height: 25px;
      display: block;
      overflow: hidden;
    }
    .actions > span.chM {text-align:center; margin-right: 4px; border-right: 1px solid $grey-light;}

    */
}

.mosaic blockquote:hover {
  border-color: #fff;
}

.mosaic blockquote:hover .listElementOver {
  display: none;
}

.mosaic blockquote > span {
  display: block;
  font-size: 0.85em;
  padding: 1px 2.5%;
  clear: both;
}

.mosaic blockquote > span.action {
  display: inline-block;
}

.mosaic blockquote > span .d,
.mosaic blockquote > span .m {
  color: #ff8a00;
  font-size: 1.6em;
}

.mosaic blockquote > span .d em,
.mosaic blockquote > span .m em {
  display: none;
}

.mosaic blockquote > span .y {
  margin-left: 4px;
  color: #b4b4b4;
}

.mosaic blockquote > span .d {
  margin-right: 2px;
}

.mosaic blockquote > span .chM {
  position: absolute;
  top: 0;
  left: -2px;
}

.mosaic blockquote span img {
  box-shadow: 0px 0px 3px #6d6d6d;
  max-width: 100%;
}

.mosaic blockquote span .actionEdit,
.mosaic blockquote .spanEdit {
  display: none !important;
}

.mosaic blockquote .sImage {
  position: relative;
  display: block;
  margin-bottom: 2px;
  min-height: 90px;
  max-height: 140px;
  background: #000;
  overflow: hidden;
}

.mosaic blockquote .sImage img {
  width: 100%;
  display: block;
}

.mosaic blockquote .actions {
  text-align: center;
  background: #fff;
  margin-top: 3px;
  margin-bottom: -3px;
}

.mosaic blockquote .actions > span {
  display: inline-block;
  box-sizing: border-box;
  padding: 1px;
  vertical-align: top;
}

.mosaic blockquote .actions span.chM {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #b4b4b4;
  line-height: 25px;
}

.mosaic blockquote > span.spanTitle,
.mosaic blockquote > span.spanName {
  color: #ff8a00;
  font-size: 1.125em;
  text-transform: uppercase;
  font-family: "ProximaNova-bold";
  letter-spacing: -.05em;
}

.mosaic blockquote .fdate {
  font-size: 1.25em;
  text-transform: uppercase;
  color: #b4b4b4;
  text-align: center;
  display: block;
}

.mosaic blockquote .fdate .d, .mosaic blockquote .fdate .m {
  font-family: "ProximaNova-bold";
  font-size: 26px;
}

.mosaic blockquote .spanId {
  display: none;
}

.listMenuMode {
  position: relative;
}

.listMenuMode div {
  position: absolute;
  top: 10px;
  right: 0;
}

.btMosaic {
  font-weight: bold;
  display: inline-block;
  padding: 0 40px;
  margin-right: 15px;
}

.list .listMenuMode .btList {
  font-weight: bold;
  display: inline-block;
  padding: 0 10px 0 38px;
}

.btList {
  font-weight: bold;
}

.listMenuMode a {
  padding-left: 38px;
  display: block;
  float: left;
  line-height: 22px;
}

div.mosaic div.listMenuMode > div > a.btMosaic {
  color: #ff8a00;
  background-image: url("/admin/i/mozaique.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 22px;
}

div.list div.listMenuMode > div > a.btMosaic {
  color: #b4b4b4;
  background-image: url("/admin/i/mozaique2.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 22px;
}

div.mosaic div.listMenuMode > div > a.btList {
  color: #b4b4b4;
  background-image: url("/admin/i/liste.png");
  background-repeat: no-repeat;
  width: 60px;
  height: 22px;
}

div.list div.listMenuMode > div > a.btList {
  color: #ff8a00;
  background-image: url("/admin/i/list2.png");
  background-repeat: no-repeat;
  width: 60px;
  height: 22px;
}

/* Order */
.orderIt #tblist blockquote,
.orderIt #tblist tr {
  cursor: url("/admin/i/dragdropSel.png"), move;
}

/* Custom dashboard */
.custom_dashboard {
  clear: both;
  padding: 15px 20px;
  margin: 20px 0;
  border: #b4b4b4 solid;
  border-width: 1px 0;
}

.custom_dashboard .buttons {
  text-align: left;
}

/* Box */
.boxHome {
  text-align: center;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.25);
}

.boxHome .userName {
  display: none;
}

.boxHome .logoWelcome {
  background-repeat: no-repeat;
  width: 669px;
  height: 404px;
  margin: 30px auto 30px;
}

.boxInfo {
  float: left;
  width: 32%;
  margin: 2% 2% 2% 0;
}

.boxInfo:nth-child(3n) {
  margin-right: 0;
}

.boxInfo .box {
  min-height: 140px;
}

.box {
  font-size: 13px;
  margin: 0;
  position: relative;
  border: 1px solid;
}

.box h2, .box p, .box table {
  margin: 0;
}

.box h2 {
  padding: 2px 10px;
  cursor: move;
}

.box p {
  padding: 10px;
}

.box h2 {
  margin: 0 0 5px;
}

.box ul {
  list-style: none;
  margin: 5px;
  padding: 0;
}

.box li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box, .box > .utils a {
  background: #fff;
  border-color: #b4b4b4;
}

.utils a {
  position: absolute;
  top: 5px;
  right: 10px;
  border: 1px solid;
  padding: 4px 8px;
}

/* Kill IE */
.killIE {
  margin: 0 auto 15px;
  width: 92%;
}

.killIE .killThem {
  border-radius: 0 0 10px 10px;
  padding: 20px;
  background: #FF6;
}

.killIE p {
  margin: 0.125em 0;
}

.killIE h2 {
  font-size: 3em;
  margin-bottom: 0;
  font-weight: bold;
}

.killIE a {
  float: left;
  display: block;
  text-align: center;
  padding: 5px;
  margin-right: 20px;
}

.killIE a:hover {
  color: #000;
  background: #ed3;
}

.killIE a span {
  display: block;
}

.killIE a em {
  display: block;
  font-size: 11px;
  font-style: normal;
}

.killIE img {
  width: 150px;
}

.killIE a.ie img {
  width: 150px;
}

/* Interface */
.listAdminInterface {
  margin: 15px 0;
}

.listAdminInterface ol {
  min-height: 18px;
  border: 1px #b4b4b4 dashed;
  padding: 8px 0 8px 20px;
  margin: 0 0 10px;
  border-left: 5px solid #ff8a00;
  list-style: none;
  counter-reset: item;
}

.listAdminInterface ol li {
  padding: 3px 30px 3px 3px;
  margin: 3px 5px;
  cursor: url("/admin/i/dragdropSel.png"), move;
  counter-increment: item;
  position: relative;
}

.listAdminInterface ol li.closed > ol {
  display: none;
}

.listAdminInterface ol li.ui-sortable-helper {
  height: auto !important;
}

.listAdminInterface ol li.ui-sortable-placeholder {
  height: 30px !important;
  background: rgba(0, 0, 0, 0.125);
  visibility: visible !important;
}

.listAdminInterface ol li > i.toggle {
  font-style: normal;
  top: 3px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 20px;
  color: #ff8a00;
}

.listAdminInterface ol li strong {
  color: #6d6d6d;
}

.listAdminInterface ol li span.description {
  color: #6d6d6d;
  display: block;
  margin: 2px;
  font-size: 0.95em;
  white-space: pre-wrap;
}

.listAdminInterface ol li span.description span {
  display: block;
}

.listAdminInterface ol li > span {
  padding-left: 25px;
  border: 1px solid transparent;
  display: block;
  margin-bottom: 3px;
}

.listAdminInterface ol li > span:before {
  content: counters(item, ".") " ";
  display: inline;
}

.listAdminInterface ol li span.nb {
  display: none;
}

.listAdminInterface ol li.protectedYes {
  cursor: default;
}

.listAdminInterface ol li.protectedYes > span > span.label:after {
  content: '🔒';
}

.listAdminInterface ol li.protectedYes > span .edit {
  width: 30px;
  display: inline-block;
}

.listAdminInterface ol li.protectedYes > span .edit a {
  display: none;
}

.listAdminInterface ol li a {
  color: #fff;
  background: #97b200;
  border-radius: 0;
  border: 3px double #6E9C00;
  display: inline-block;
  margin-left: 5px;
  padding: 0 5px;
}

.listAdminInterface ol li a:hover {
  color: #fff;
  background: #a2d122;
}

.listAdminInterface ol li .actions {
  display: none;
}

.listAdminInterface ol li .actions .edit a:before {
  content: '✎ ';
}

.listAdminInterface ol li .actions .addInside a:before {
  content: '↴ ';
}

.listAdminInterface ol li .actions .addBefore a:before {
  content: '⇡ ';
}

.listAdminInterface ol li .actions .addAfter a:before {
  content: '⇣ ';
}

.listAdminInterface ol li > span:hover .actions {
  display: inline;
}

.listAdminInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface {
  background: #F9F9F9;
}

.listAdminInterface .listboxInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
.listAdminInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface {
  background: #fff;
}

/* Crop */
body.pCrop {
  background: #222;
}

body.pCrop #content {
  padding: 0;
  margin: 0;
  width: auto;
  background: none;
}

.cropInterface {
  margin: 5px 0 0;
  text-align: center;
}

.cropInterface .jcrop-holder {
  margin: 0 auto;
}

.cropInterface .bt,
.fileUpload a.btCrop {
  display: inline-block;
}

ul.nested {
  margin: 0;
  padding: 0;
  border: solid #ddd;
  border-width: 1px;
  background: #fff;
}

ul.nested > li {
  border-top: 1px solid #ddd;
}

ul.nested > li:first-child {
  border: none;
}

ul.nested ul {
  padding: 0;
  margin: 0;
}

ul.nested li {
  float: none;
  list-style: none;
  padding: 4px 2px 4px 4px;
  margin: 0;
}

ul.nested li li:before {
  content: "↳";
}

ul.nested > li li {
  padding-top: 0;
  padding-left: 25px;
}

ul.nested > li li li {
  padding-left: 40px;
}

.toggle {
  position: absolute;
  content: "\2304";
  top: 0;
  left: 0;
  min-width: 20px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: block;
}

.toggle:before {
  content: '\2304';
}

.toggle:hover:before {
  content: '>';
}

.closed .toggle:before {
  content: '>';
}

.closed .toggle:hover:before {
  content: '\2304';
}

.navigation > ul > li em.close span {
  display: none;
}

.navigation > ul > li em.close {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  background: url("/admin/i/cross.png") no-repeat center center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.navigation > ul > li em.close:hover,
.navigationWrapper .navigation > ul > li.active em.close,
.navigationWrapper .navigation > ul > li.sel em.close {
  background: url("/admin/i/crossSel.png") no-repeat center center;
}

.prev,
.next {
  position: absolute;
  top: 50px;
  display: block;
  height: 27px;
  width: 30px;
  cursor: pointer;
}

.prev,
.nav1Prev {
  background: url("/admin/i/navArrowLeft.png") no-repeat center center;
  left: -30px;
}

.next,
.nav1Next {
  background: url("/admin/i/navArrowRight.png") no-repeat center center;
  right: -30px;
}

.prev > span, .next > span {
  display: none;
}

.prev.disabled, .next.disabled {
  visibility: hidden;
}

.navigationWrapper {
  position: relative;
}

.nav1Prev,
.nav1Next {
  display: block;
  width: 1.8%;
  position: absolute;
  top: 0;
}

.nav1Prev {
  left: 6.1%;
}

.nav1Next {
  right: 0.1%;
}

.nav1Prev span,
.nav1Next span {
  display: block;
  height: 34px;
  line-height: 34px;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.nav1Prev.disabled, .nav1Next.disabled {
  visibility: hidden;
}

.menuCustomWrapper {
  position: absolute;
  top: 10px;
  left: 25%;
  max-width: 60%;
}

.menuCustom {
  visibility: hidden;
}

.menuCustom > ul {
  height: 50px;
  margin: 0;
  padding: 0;
}

.menuCustom > ul > li {
  list-style: none;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}

.menuCustom > ul > li a {
  display: block;
  color: #000;
  width: 120px;
  height: 115px;
  text-align: center;
}

#menuCustom > ul > li a {
  font-size: 20px;
  font-family: "ProximaNova-light";
  color: #b4b4b4;
  background-image: url("/admin/i/pictos-sokl.png");
  background-repeat: no-repeat;
}

#menuCustom > ul > li a span {
  padding-top: 71px;
  display: block;
}

.menuCustom > ul > li a {
  color: #595959;
}

.menuCustom > ul > li a em.close {
  display: none;
}

.menuCustom > ul > li.sepa {
  display: none;
}

.menuCustom #nav_menuhome {
  display: none;
}

#menuCustom > ul > li > a {
  color: #595959;
}

#menuCustom > ul > li > a:hover {
  cursor: url("/admin/i/dragdropSel.png"), move;
  color: #fff;
  background-position: 0 -132px;
}

/*picto navigation*/
#menuCustom #nav_publications a,
.pPublications h2.pageTitle em.picto,
#menuCustom #nav_pages a,
.pPages h2.pageTitle em.picto {
  background-position: -784px 0;
}

#menuCustom #nav_highlights a,
.pHighlights h2.pageTitle em.picto {
  background-position: -1825px 0;
}

#menuCustom #nav_playlists a,
.pPlaylists h2.pageTitle em.picto {
  background-position: -130px 0;
}

#menuCustom #nav_people a,
.pPeople h2.pageTitle em.picto {
  background-position: -1570px 0;
}

#menuCustom #nav_nlcreas a,
.pNlcreas h2.pageTitle em.picto {
  background-position: -1957px 0;
}

#menuCustom #nav_layouts a,
.pLayouts h2.pageTitle em.picto {
  background-position: -1435px 0;
}

#menuCustom #nav_files a {
  background-position: -527px 0;
}

#menuCustom #nav_projects a,
.pFiles h2.pageTitle em.picto,
.pProjects h2.pageTitle em.picto {
  background-position: -527px 0;
}

#menuCustom #nav_todolists a,
#menuCustom #nav_rooms a,
.pRooms h2.pageTitle em.picto {
  background-position: -2085px 0;
}

#menuCustom #nav_articles a,
.pArticles h2.pageTitle em.picto {
  background-position: -1174px 0;
}

#menuCustom #nav_dates a,
.pDates h2.pageTitle em.picto {
  background-position: -1045px 0;
}

#menuCustom #nav_tickets a,
#menuCustom #nav_ticketssettings a,
.pTickets h2.pageTitle em.picto,
.pTicketssettings h2.pageTitle em.picto {
  background-position: -915px 0;
}

#menuCustom #nav_advpubs a,
.pAdvpubs h2.pageTitle em.picto {
  background-position: -1694px 0;
}

#menuCustom #nav_gallery_categories a,
.pGallery_categories h2.pageTitle em.picto {
  background-position: -265px 0;
}

#menuCustom #nav_bands a,
.pBands h2.pageTitle em.picto {
  background-position: -5px 0;
}

#menuCustom #nav_menus a,
.pMenus h2.pageTitle em.picto {
  background-position: -2216px 0;
}

#menuCustom #nav_maps a,
.pMaps h2.pageTitle em.picto {
  background-position: -2348px 0;
}

#menuCustom #nav_form_creates a,
.pForm_creates h2.pageTitle em.picto {
  background-position: -2480px 0;
}

#menuCustom #nav_publications a:hover,
#menuCustom #nav_pages a:hover {
  background-position: -784px -132px;
}

#menuCustom #nav_highlights a:hover {
  background-position: -1825px -132px;
}

#menuCustom #nav_playlists a:hover {
  background-position: -130px -132px;
}

#menuCustom #nav_people a:hover {
  background-position: -1570px -132px;
}

#menuCustom #nav_nlcreas a:hover {
  background-position: -1957px -132px;
}

#menuCustom #nav_layouts a:hover {
  background-position: -1435px -132px;
}

#menuCustom #nav_files a:hover {
  background-position: -527px -132px;
}

#menuCustom #nav_projects a:hover {
  background-position: -527px -132px;
}

#menuCustom #nav_todolists a:hover {
  background-position: -1303px -132px;
}

#menuCustom #nav_rooms a:hover {
  background-position: -2085px -132px;
}

#menuCustom #nav_articles a:hover {
  background-position: -1174px -132px;
}

#menuCustom #nav_dates a:hover {
  background-position: -1045px -132px;
}

#menuCustom #nav_tickets a:hover,
#menuCustom #nav_ticketssettings a:hover {
  background-position: -915px -132px;
}

#menuCustom #nav_advpubs a:hover {
  background-position: -1694px -132px;
}

#menuCustom #nav_gallery_categories a:hover {
  background-position: -265px -132px;
}

#menuCustom #nav_bands a:hover {
  background-position: -5px -132px;
}

#menuCustom #nav_menus a:hover {
  background-position: -2216px -132px;
}

#menuCustom #nav_maps a:hover {
  background-position: -2348px -132px;
}

#menuCustom #nav_form_creates a:hover {
  background-position: -2480px -132px;
}

.insertMe {
  font-size: 20px;
  text-align: center;
  width: 150px !important;
  overflow: hidden;
  padding: 0 !important;
  display: inline-block;
  cursor: url("/admin/i/dragdropSel.png"), move;
  text-transform: uppercase;
}

.insertMe span {
  background: #feb734;
  display: block;
  padding: 5px 0;
}

.insertMe em {
  background: url("/admin/i/insertHere.png") no-repeat center bottom;
  height: 30px;
  display: block;
}

.navigation > ul > li.insertHere {
  display: none;
}

ul .ui-state-highlight {
  background: url("/admin/i/insertHere.png") no-repeat center center;
  width: 160px;
  height: 40px;
  display: inline-block;
  border: none;
  margin: 0 15px;
}

.navigation > ul > li.insertHere span {
  display: none;
}

.stats-content h2 {
  background: #feb734;
  padding: 0.3125em;
  margin-top: 2.5em;
  margin-bottom: 0;
  color: white;
  font-family: ProximaNova, Helvetica, Arial, sans-serif;
  font-size: 1.25em;
}

.stats-content .pie-choices {
  width: 100%;
  min-height: 400px;
  margin-bottom: -40px;
}

.stats-content ul {
  padding: 0;
  margin: 0;
}

.stats-content ul li {
  list-style: none;
  width: 100%;
  background: #CCC;
  box-sizing: border-box;
  padding: 0.3125em;
}

.stats-content ul li:nth-child(2n+1) {
  background-color: rgba(0, 0, 0, 0.0980392);
}

#mailjet .bar {
  background: rgba(0, 0, 0, 0.1);
  height: 50px;
  position: relative;
}

#mailjet .bar .process {
  background: #97b200;
  height: 50px;
}

#mailjet .bar .process .info {
  position: absolute;
  width: auto;
  box-sizing: border-box;
  color: white;
  font-size: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#mailjet .timer-content {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 30px;
  overflow: hidden;
}

#mailjet .timer-content .timer {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.2);
  float: left;
}

#mailjet input {
  border-bottom: 1px solid black;
}

#mailjet .item {
  opacity: 0.2;
  margin-bottom: 10px;
  padding: 5px;
  box-sizing: border-box;
  transition: background-color 0.5s ease;
}

#mailjet .item.done, #mailjet .item.processing {
  opacity: 1;
  color: white;
}

#mailjet .item.done {
  background-color: #97b200;
}

#mailjet .item.processing {
  background-color: #ff8a00;
}

#mailjet .send button {
  background: #feb734;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 20%;
  display: block;
  color: white;
  cursor: pointer;
}

#mailjet .send button:hover {
  background: #ff8a00;
}

#mailjet h2 {
  font-size: 2em;
}

#mailjet table {
  border: 1px solid #999;
}

#mailjet td,
#mailjet th {
  border-bottom: 1px solid #999;
  padding: 5px;
}

#mailjet th {
  background: #eaeaea;
}

#mailjet tr:nth-child(2n) th {
  background: #f2f2f2;
}

#mailjet td {
  text-align: right;
}

#mailjet th a {
  color: inherit;
  text-decoration: none;
}

#mailjet .messageInfo {
  margin-top: 1em;
}

#mailjet .legend {
  margin-top: 3px;
  font-size: .86em;
}

#mailjet a.bt {
  display: inline-block;
}

#mailjet .loader {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
}

#mailjet .loader img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#mailjet button.send, #mailjet button.stop {
  float: right;
  background-color: #feb734;
  border: none;
  color: white;
  margin-right: 10px;
  padding: 5px;
}

#mailjet button.send:hover, #mailjet button.stop:hover {
  background-color: #ff8a00;
}

.time-picker input.display-time {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: auto !important;
}

.vdp-datepicker {
  display: inline-block;
}

.lds-grid {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #eaeaea;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.pag {
  padding: 0;
}

.pag li {
  display: inline-block;
  margin-right: .3%;
  color: #ff8a00;
  cursor: pointer;
}

.pag li.active {
  cursor: default;
  color: #000;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "ProximaNova-light";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Light.otf");
}

@font-face {
  font-family: "ProximaNova-bold";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Bold.otf");
}

#form_modules {
  border: 1px solid #b4b4b4;
  padding: 0;
}

#form_modules > ul {
  background: #97b200;
  margin: 0;
  padding: 0;
  padding-bottom: 40px;
  border: 1px solid #fff;
}

#form_modules > ul::after {
  clear: both;
  content: "";
  display: table;
}

#form_modules > ul li {
  margin: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

#form_modules > ul a {
  display: block;
  height: 70px;
  padding-left: 70px;
  padding-top: 25px;
  text-align: left;
  width: 145px;
  font-weight: bold;
  background-image: url("/admin/i/addStar.png");
  background-repeat: no-repeat;
  background-position: 5px 5px;
  box-sizing: border-box;
}

#form_modules > ul a span {
  color: #ffffff;
  padding-right: 7px;
}

#form_modules > ul a span:before {
  content: 'Ajouter ';
}

#form_modules > ul a:hover {
  color: #000;
}

#form_modules > ul #ModuleVideo a {
  background-image: url("/admin/i/addVideo.png");
}

#form_modules > ul #ModuleAudio a {
  background-image: url("/admin/i/addMp3.png");
}

#form_modules > ul #ModuleEmbed a {
  background-image: url("/admin/i/addCode.png");
}

#form_modules > ul #ModuleLink a {
  background-image: url("/admin/i/addLink.png");
}

#form_modules > ul #ModuleFile a,
#form_modules > ul #ModuleFileManagement a {
  background-image: url("/admin/i/addFile.png");
}

#form_modules > ul #ModuleTilte a,
#form_modules > ul #ModuleText a {
  background-image: url("/admin/i/addText.png");
}

#form_modules > ul #ModuleTextimage a,
#form_modules > ul #ModuleNlitem a {
  background-image: url("/admin/i/addTextimage.png");
}

#form_modules > ul #ModuleGallery a,
#form_modules > ul #ModuleGalleryList a,
#form_modules > ul #ModuleImageDuo a,
#form_modules > ul #ModuleImageTrio a,
#form_modules > ul #ModuleImage a {
  background-image: url("/admin/i/addImage.png");
}

#form_modules > ul #ModuleSearch a {
  background-image: url("/admin/i/addSearch.png");
}

#form_modules > ul #ModuleInclude a {
  background-image: url("/admin/i/addInclude.png");
}

#form_modules > ul #ModulePerson a,
#form_modules > ul #ModulePeopleContact a {
  background-image: url("/admin/i/addPeople.png");
}

#form_modules > ul #ModuleNetwork a {
  background-image: url("/admin/i/addNetwork.png");
}

#form_modules > ul #ModulePlaylist a {
  background-image: url("/admin/i/addPlaylist.png");
}

#form_modules > ul #ModuleBooklet a {
  background-image: url("/admin/i/addBooklet.png");
}

#form_modules > ul #ModuleLikebox a {
  background-image: url("/admin/i/addLikebox.png");
}

#form_modules > ul #ModuleTwitterbox a {
  background-image: url("/admin/i/addTwitterbox.png");
}

#form_modules > ul #ModuleIcal a {
  background-image: url("/admin/i/addIcal.png");
}

#form_modules > ul #ModuleForm a {
  background-image: url("/admin/i/addCheckbox.png");
}

#form_modules .collection-container {
  margin: 15px;
}

#form_modules .form-group {
  padding: 0;
  border-top: 0;
}

#form_modules h2 {
  box-sizing: border-box;
  padding: 5px 5px 5px 40px;
  margin: 0;
  min-height: 35px;
  background-image: url("/admin/i/hachureSelected.png");
  background-color: #ff8a00;
  color: #fff;
  font-weight: normal;
  position: relative;
  font-family: "ProximaNova-light";
  cursor: url("/admin/i/dragdropSel.png"), move;
}

#form_modules h2 .toggle {
  width: 35px;
  line-height: 35px;
  background: rgba(0, 0, 0, 0.5);
}

#form_modules .module-item.closed > div.form-group {
  display: none;
}

#form_modules .module-item.closed > div.form-group.tools-module {
  display: inherit;
}

#form_modules .module-item {
  position: relative;
  border-bottom: 2px dashed #ff8a00;
  margin-bottom: 15px;
}

#form_modules .module-item > div {
  transition: all ease .25s;
}

#form_modules .module-item .fileUploadDel {
  display: none;
}

#form_modules .module-item .form-group {
  padding: 10px;
  background: rgba(0, 0, 0, 0.075);
}

#form_modules .module-item .tools-module {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 0;
  margin: 0;
  background: none;
}

#form_modules .module-item .tools-module::after {
  clear: both;
  content: "";
  display: table;
}

#form_modules .module-item .tools-module ul {
  margin: 0;
  padding: 0;
}

#form_modules .module-item .tools-module li {
  float: left;
  list-style: none;
}

#form_modules .module-item .tools-module li a {
  display: block;
  height: 30px;
  padding-left: 30px;
  font-size: 10px;
  color: #fff;
  line-height: 30px;
  background: no-repeat top left;
  text-decoration: none;
  margin-right: 15px;
}

#form_modules .module-item .tools-module li.first a {
  background-image: url("/admin/i/first.png");
}

#form_modules .module-item .tools-module li.last a {
  background-image: url("/admin/i/last.png");
}

#form_modules .module-item .tools-module li.up a {
  background-image: url("/admin/i/up.png");
}

#form_modules .module-item .tools-module li.down a {
  background-image: url("/admin/i/down.png");
}

#form_modules .module-item .tools-module li.delete a {
  background-image: url("/admin/i/delete.png");
}

/* SOKL */
#head a {
  background: url("/admin/i/sokl-logo.png") no-repeat;
  height: 76px;
}

.boxHome .logoWelcome {
  background: url("/admin/i/sokl.png") no-repeat;
}

.pUnknow #head a {
  background: url("/admin/i/soklLogin.png") no-repeat;
  height: 76px;
}

.pUnknow #head a {
  width: 585px;
  height: 273px;
  margin: 0 auto;
  display: block;
}

#head span {
  display: none;
}

#head {
  padding: 5px 0 0 0;
  height: 125px;
  width: 20%;
  line-height: 1em;
  margin-left: 8.0%;
}

.pUnknow {
  background-image: url("/admin/i/bkgAdmin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #d0d0d0;
}

#welcome li.logout a {
  background: url("/admin/i/pictoLogout.png") center right no-repeat;
  padding: 3px 20px 3px 3px;
}

#welcome li.logout a:hover {
  background: url("/admin/i/pictoLogoutHover.png") center right no-repeat;
  color: #feb734;
}

/* Login */
.pUnknow {
  padding-top: 1.5%;
}

.pUnknow #footer {
  display: none;
}

.pUnknow #head {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 auto;
}

.pUnknow #header,
.pUnknow #content {
  background: none;
}

.pUnknow .navigationWrapper,
.pUnknow .menuCustomWrapper {
  display: none;
}

.login {
  margin-left: auto;
  margin-right: auto;
}

.login > div {
  width: 360px !important;
}

.loginFormArea input.txt {
  border-width: 15px 15px;
  border-color: #feb734;
  border-style: solid;
  color: #b4b4b4;
  font-weight: 300;
}

.pBox #content {
  width: 100%;
  margin: 0;
}

/* popUp */
body.pPopup #content {
  border-radius: 3px;
  width: auto;
  margin-right: 100px;
}

#navigation {
  margin: 0 2% 0 8.0%;
}

#navigation > ul {
  height: 34px;
  overflow: hidden;
}

.navigation > ul > li {
  display: inline-block !important;
  margin: 0 1px 0 0;
  float: left;
}

#navigation ul li a {
  text-transform: uppercase;
  font-size: 20px;
}

#navigation2 {
  border-top: 2px solid #fff;
  height: 34px;
  width: auto;
}

#navigation2 > ul {
  padding: 0 2% 0 8.0%;
}

#navigation2 > ul > li {
  display: inline;
}

#navigation2 > ul > li a {
  background-color: #ff8a00;
  border: medium none;
  display: block;
  line-height: 32px;
  text-align: center;
  padding: 0;
  margin-right: 25px;
}

.navigation.navigation3 {
  padding-top: 10px;
  border-bottom: 2px solid #ff8a00;
}

.navigation.navigation3 > ul {
  padding: 0 1% 0 0;
  margin: 0 3% 4px 8.0%;
  height: 32px;
}

.navigation.navigation3 > ul > li a {
  border-radius: 0;
  height: 31px;
  margin-right: 40px;
  padding: 0;
  line-height: 31px;
  overflow: hidden;
  background: none;
}

.navigation.navigation3 > ul > li a:hover,
.navigation.navigation3 > ul > li.sel a,
.navigation.navigation3 > ul > li.active a {
  background: url("/admin/i/arrowMenu3.png") no-repeat center bottom;
  padding-bottom: 5px;
  font-weight: bold;
}

#navigationAdmin {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 13px 0;
  z-index: 10;
}

#navigationAdmin ul {
  padding: 0 3% 0 8.0%;
}

.navigation.navigationAdmin {
  transition: all 1s;
}

.navigation.navigationAdmin:hover {
  transition: all 1s;
}

.navigation.navigationAdmin > ul > li a {
  background: none;
  color: #fff;
  margin: 0;
  border: none;
}

table td {
  font-size: 12px;
  color: #6d6d6d;
}

#insertForm {
  display: block;
  min-height: 550px;
  border-right: 1px solid #F2F2F1;
  padding-right: 4px;
}

form > .form-group,
.lang-content > .form-group {
  background-image: url("/admin/i/grisHachureEditable.gif");
  border-top: 10px solid #fff;
  padding: 15px 0 15px 30px;
}

.form-group::after {
  clear: both;
  content: "";
  display: table;
}

.form-group > label {
  font-family: "ProximaNova-light";
  font-size: 18px;
  color: #000;
  display: block;
  width: 20%;
  float: left;
}

.form-group textarea {
  width: 100%;
}

.form-group > input, .form-group > .textarea, .form-group .mce-tinymce, .form-group .choice-group {
  width: 58%;
  margin-left: 1%;
  float: left;
}

.form-group .choice-group ul {
  margin: 0;
  padding: 0;
}

.form-group .choice-group ul li {
  list-style: none;
  margin-bottom: 5px;
}

.form-group .choice-group ul li input {
  width: auto;
}

.form-group .choice-group ul li label {
  width: auto;
  margin-left: 5px;
  cursor: pointer;
}

.form-group > input.datePicker,
.form-group > input.timePicker,
.form-group > input.colorPicker {
  width: 25%;
}

.form-group div.colorTarget {
  float: left;
  margin-left: 10px;
}

.form-group > select, .form-group .form-control-static {
  max-width: 58%;
  margin-left: 1%;
  float: left;
}

.form-group .select2 {
  width: 100% !important;
}

.checkbox-resource {
  display: inline-block;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin-bottom: 1%;
  margin-left: .5%;
  width: 31%;
  border-radius: 5px;
  box-sizing: border-box;
  height: 130px;
}

.checkbox-resource .nested-item.hide {
  display: none;
}

.checkbox-resource input {
  width: auto;
}

.checkbox-resource label {
  width: auto;
  display: inline-block;
  float: none;
}

.text-danger,
.help-block {
  float: right;
  display: block;
  margin: 0 1% 0 0;
  width: 18%;
  box-sizing: border-box;
  line-height: 1.125em;
  font-size: .86em;
  background: rgba(255, 255, 255, 0.75);
  border-left: 1px solid #b4b4b4;
  padding: 5px;
  cursor: pointer;
}

.text-danger:hover,
.help-block:hover {
  font-size: 1.5em;
  background: #fff;
}

.text-danger {
  background: #f6005b !important;
  color: #fff !important;
}

.subtitle h2.separator {
  font-weight: normal;
  color: #fec14f;
  font-size: 1.5em;
  padding: 3em 0 0;
  font-weight: normal;
}

.subtitle a {
  color: #ff8a00;
  text-decoration: underline;
}

.inputLong input {
  font-size: 18px;
  color: #000;
}

.inputLong select, .inputLong li em {
  font-size: 16px;
  color: #000;
}

/* hide id field in list */
.admintblist .thId,
.admintblist .tdId {
  display: none;
}

#content {
  margin-right: auto;
  margin-left: 8.0%;
  padding: 1px 0 10px;
  width: 80%;
  min-height: 400px;
}

h2.pageTitle {
  font-size: 70px;
  margin: 0 -15.0% 0 -10%;
  font-family: "ProximaNova-light";
  padding: 40px 0 5px;
  background-image: url("/admin/i/grisHacheFiltre.png");
}

h2.pageTitle em.picto {
  background-image: url("/admin/i/pictos-sokl.png");
  background-repeat: no-repeat;
  display: block;
  width: 120px;
  height: 75px;
  position: absolute;
  top: 35px;
  left: 6%;
}

h2.pageTitle a.edit {
  background: url("/admin/i/pictoEdit.png") right 15px no-repeat;
  display: inline-block;
  padding-right: 30px;
}

h2.pageTitle span {
  line-height: 65px;
  padding-left: 84px;
  margin-left: 8%;
  letter-spacing: -0.025em;
}

p.error {
  background-image: url("/admin/i/error.png");
}

p.success {
  background-image: url("/admin/i/success.png");
}

p.info {
  background-image: url("/admin/i/success.png");
}

.buttons.buttonsTop {
  position: relative;
  top: 40px;
}

.buttons.buttonsTop .buttonsInner {
  padding-top: 5px;
  padding-bottom: 70px;
  background-image: url("/admin/i/grisHachureEditable.png");
  margin-top: 10px;
  right: -104px;
  position: absolute;
  text-align: center;
  width: 94px;
  z-index: 9;
}

.buttonsBot {
  display: none;
}

.edit .buttons.buttonsTop {
  top: 50px;
}

.btform {
  background-image: url("/admin/i/btnDefault.png");
}

.btsend {
  display: block;
  background-image: url("/admin/i/btnSave.png");
}

.btformAdd {
  display: block;
  background-image: url("/admin/i/btnAdd.png");
}

.btformAddbatch {
  display: block;
  background-image: url("/admin/i/btnAddBatch.png");
}

.btform.btformExport {
  background-image: url("/admin/i/btnExport.png");
}

.btformExportXlsMac {
  display: none;
}

.btformExportXlsWin {
  display: none;
}

.btformedit {
  background-image: url("/admin/i/btnEdit.png");
}

.btEditGroup {
  background-image: url("/admin/i/editerSelection.png");
}

.btDeleteGroup {
  background-image: url("/admin/i/btnDeleteSelection.png");
}

.submit_and_back {
  background-image: url("/admin/i/btnEnregistrerList.png");
}

.submit_and_preview {
  background-image: url("/admin/i/btnPreview.png");
}

.submit_and_add {
  background-image: url("/admin/i/btnEnregistrerElement.png");
}

.submit_duplicate {
  background-image: url("/admin/i/btnEnregistrerElement.png");
}

.btformdelete {
  background-image: url("/admin/i/btnDelete.png");
}

.btformback {
  background-image: url("/admin/i/btnBackList.png");
}

.btformOrder {
  background-image: url("/admin/i/btnOrder.png");
}

.btMailtoGroup {
  background-image: url("/admin/i/btnMailGroup.png");
}

.btsend.submit_code {
  background-image: url("/admin/i/btnShowCode.png");
}

.btsend.submit_see {
  background-image: url("/admin/i/btnPreview.png");
}

.btform.btformImport, .btImport,
#btImport {
  background: url("/admin/i/btnImport.png") no-repeat center top;
}

.btsend.btsendEmail {
  background-image: url("/admin/i/btnSendMsg.png");
}

#filter {
  background-image: url("/admin/i/grisHacheFiltre.png");
}

.actionEdit a {
  background: url("/admin/i/tdEditer.png") no-repeat center center;
  display: block;
  width: 23px;
  height: 23px;
}

.actionEdit a span {
  display: none;
}

.actionDelete a {
  background: url("/admin/i/tdDelete.png") no-repeat center center;
  display: block;
  width: 23px;
  height: 23px;
}

.actionDelete a span {
  display: none;
}

.actionCustom .preview a {
  background: url("/admin/i/tdCustom.png") no-repeat center center;
  display: block;
  width: 23px;
  height: 23px;
}

.actionCustom .preview a span {
  display: none;
}

.actionCustom .actionMore a {
  color: #fff !important;
  display: inline-block;
  padding: 2px 6px;
  margin: 2px;
  background: #97b200;
  border: 3px double #6E9C00;
  border-radius: 0;
  text-align: center;
}

.module_edit {
  border-bottom: 2px dashed #ff8a00;
  background-image: url("/admin/i/grisHachureEditable.gif");
}

.module_edit:last-child {
  border-bottom: 2px solid #97b200;
}

.navigation > ul > li > a {
  display: block;
  text-decoration: none;
  font-size: 1.125em;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: #feb734;
  overflow: hidden;
  padding-right: 30px;
  height: 34px;
  line-height: 28px;
  padding-left: 15px;
}

.navigation a:hover, .navigation li.sel a, .navigation li.active a {
  border-color: rgba(255, 255, 255, 0.25);
}

.navigation1 > ul > li > a:hover,
.navigation1 > ul > li.sel > a,
.navigation1 > ul > li.active > a,
.navigation2 > ul > li > a:hover,
.navigation2 > ul > li.sel a,
.navigation2 > ul > li.active a {
  background-image: url("/admin/i/arrow.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

#navigationAdmin > ul > li:first-child a {
  border: none;
}

#navigationAdmin > ul > li a {
  background-position: 35px 5px;
  background-repeat: no-repeat;
  border-left: 1px solid #fff;
  display: block;
  height: 45px;
  line-height: 45px;
  padding-left: 88px;
}

#nav_home a {
  background-image: url("/admin/i/pictoHome.png");
}

#nav_viewsite a {
  background-image: url("/admin/i/pictoViewSite.png");
  background-position: 35px 10px !important;
}

#nav_menuusers a {
  background-image: url("/admin/i/pictoUsers.png");
}

#nav_profile a {
  background-image: url("/admin/i/pictoProfil.png");
}

#nav_menuinfostech a {
  background-image: url("/admin/i/pictoInfosTech.png");
}

#navigationAdmin > ul > li,
#navigationAdmin > ul > li a {
  font-size: 18px;
}

.btsend,
.btform {
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0 10px;
  text-align: center;
  background-position: center top;
  background-repeat: no-repeat;
}

.btform span,
.btsend.submit_code span,
.btsend.submit_see span {
  font-size: 13px;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  display: block;
  padding-top: 55px;
}

.btsend span,
.btformAdd span {
  color: #97b200;
  font-weight: bold;
  text-align: center;
  display: block;
  font-size: 16px;
  padding-top: 74px;
}

.admintblist td, .admintblist th {
  font-size: 16px;
  vertical-align: center;
}

.admintblist td.tdTitle {
  font-size: 16px;
  font-family: "ProximaNova-bold";
}

div.mosaic .listElementOver {
  background-image: url("/admin/i/grisHachure.png");
  background-position: center center;
  width: 94%;
  position: absolute;
  display: block;
  height: 99%;
  margin: 0.5%;
  z-index: 1;
}

div.mosaic .sel .listElementOver {
  background-image: url("/admin/i/hachureSelected.png");
}

#filter {
  margin: 0 -15% 0 -10%;
  padding: 5px 2% 0 9.5%;
  border-top: solid 2px white;
}

/* Animation */
/*.messageWrapperIn {
	position:absolute;
	left:0;
	width:100%;
	z-index:10;
}

*/
.messageWrapper {
  margin: 0 -15% 0 -10%;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

.messageWrapper .message.off {
  padding-top: 25px;
  padding-bottom: 25px;
}

.messageWrapper .message {
  font-size: 18px;
  padding-top: 125px;
  padding-bottom: 125px;
  visibility: visible;
  line-height: 1.25em;
}

.messageWrapper .message a {
  text-decoration: underline;
  color: #ff8a00;
}

@media only screen and (max-width: 1400px) {
  #head {
    margin-left: 6%;
  }
}

@media only screen and (max-width: 1300px) {
  #head {
    margin-left: 3%;
  }
}

@media only screen and (max-width: 1200px) {
  #head {
    margin-left: 0.5%;
    width: 21%;
  }
  #head a {
    background-size: contain;
  }
  #welcome .date {
    display: none;
  }
}

.nested {
  padding-left: 20px;
}

.module-item .select2-container {
  max-width: 100% !important;
}

.admintblist.newsletter .process {
  display: none;
}

.choice-group .info-global {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.preview {
  position: absolute;
  top: 1%;
  height: 98%;
  left: 2%;
  width: 96%;
  background-color: white;
  z-index: 1000;
  overflow: hidden;
}

.preview a.remove {
  position: absolute;
  right: 9px;
  top: 9px;
}

.preview iframe {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: calc(100% - 30px);
}

.preview ul {
  width: 100%;
  background: #ff8a00;
  height: 40px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.preview ul li {
  display: inline-block;
  padding: 15px;
  background: #feb734;
  height: 40px;
  vertical-align: top;
  box-sizing: border-box;
  text-transform: uppercase;
}

.preview ul li:hover a {
  color: #7d0606;
}

.preview ul li a {
  color: white;
}

.cover-preview {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  z-index: 900;
}

.task {
  position: fixed;
  top: 0;
  left: 50%;
  background: #fff;
  text-align: center;
  z-index: 1000;
  width: 50%;
  display: none;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  box-shadow: 1px 1px 12px #555;
}

.task.active {
  display: block;
}

.task .title {
  background: #feb734;
  position: relative;
}

.task .title a {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #fff;
  font-weight: bold;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.task .title a:hover {
  color: #000;
}

.task h1 {
  text-transform: uppercase;
  font-size: 18px;
  padding: 10px;
  color: #fff;
}

.task ul {
  padding: 0;
  margin: 0;
}

.task li {
  margin: 0;
  list-style: none;
  text-align: left;
  border-bottom: 1px dashed #6d6d6d;
  min-height: 70px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.task li .content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.task li .content .item {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.task li .content .item .pourcent {
  position: absolute;
  right: 10px;
}

.task li .cover {
  background: #97b200;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.popup-selection {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  min-height: 200px;
  padding: 0 15px;
  box-sizing: border-box;
  background-image: url(/admin/i/grisHachureEditable.gif);
  box-shadow: 1px 1px 12px #555;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
}

.popup-selection h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  color: white;
}

.popup-selection .remove {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #feb734;
}

.popup-selection .remove::after {
  clear: both;
  content: "";
  display: table;
}

.popup-selection .remove a {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
}

.popup-selection .category {
  position: absolute;
  top: 56%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.popup-selection input {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.popup-selection button {
  background-color: #97b200;
  display: block;
  border: none;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: 30%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 15px;
  cursor: pointer;
  outline: none;
}

.popup-selection button:disabled,
.popup-selection button[disabled] {
  opacity: 0.2;
  cursor: no-drop;
}

.img_source {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 20000;
  height: calc(100% - 58px);
}

.img_source img {
  max-width: 100%;
}

.img_source .tooltip {
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px #555;
}

.img_source .tooltip a {
  display: inline-block;
}

.mce-flow-layout-item.mce-last {
  display: none;
}

.mce-container-body .mce-resizehandle {
  display: none;
}
