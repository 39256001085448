
.navigation > ul > li em.close span {display:none;}
.navigation > ul > li em.close { position: absolute; top: 1px; right: 1px; display: block; background: url('/admin/i/cross.png') no-repeat center center; width: 20px; height: 20px; border-radius: 3px; }
.navigation > ul > li em.close:hover,
.navigationWrapper .navigation > ul > li.active em.close,
.navigationWrapper .navigation > ul > li.sel em.close { background: url('/admin/i/crossSel.png') no-repeat center center; }


.prev,
.next {
position: absolute;
top: 50px;
display: block;
height: 27px;
width: 30px;
cursor: pointer;
}
.prev,
.nav1Prev {
background: url("/admin/i/navArrowLeft.png") no-repeat center center;
left: -30px;
}
.next,
.nav1Next {
background: url("/admin/i/navArrowRight.png") no-repeat center center;
right:-30px;
}
.prev>span, .next>span{display:none;}
.prev.disabled, .next.disabled {visibility: hidden;}
.navigationWrapper{ position: relative;}
.nav1Prev,
.nav1Next{ display: block;  width:1.8%; position: absolute; top:0; }
.nav1Prev {left:6.1%;}
.nav1Next {right:0.1%}
.nav1Prev span,
.nav1Next span {display:block; height: 34px; line-height: 34px; font-size:24px; text-align: center; color:#fff}
.nav1Prev.disabled, .nav1Next.disabled {visibility: hidden;}
.menuCustomWrapper {position:absolute; top:10px; left:25%; max-width:60%;}
.menuCustom {visibility: hidden;}
.menuCustom > ul { height: 50px; margin: 0; padding: 0;}
.menuCustom > ul > li { list-style: none; display: block; float: left; margin: 0 10px 0 0;}
.menuCustom > ul > li a { display: block; color: #000; width: 120px; height: 115px; text-align: center; }
#menuCustom > ul > li a {font-size:20px;font-family: $typoLight; color:$grey-light;background-image: url('/admin/i/pictos-sokl.png'); background-repeat:no-repeat;}
#menuCustom > ul > li a span{padding-top:71px;display:block;}
.menuCustom > ul > li a {color:#595959;}
.menuCustom > ul > li a em.close { display: none;}
.menuCustom > ul > li.sepa { display: none;}
.menuCustom #nav_menuhome { display: none;}
#menuCustom > ul > li > a { color: #595959; }
#menuCustom > ul > li > a:hover { cursor: url('/admin/i/dragdropSel.png'), move; color: #fff; background-position: 0 -132px; }

/*picto navigation*/

#menuCustom #nav_publications a,
.pPublications h2.pageTitle em.picto,
#menuCustom #nav_pages a,
.pPages h2.pageTitle em.picto { background-position: -784px 0; }
#menuCustom #nav_highlights a,
.pHighlights h2.pageTitle em.picto { background-position:-1825px 0; }
#menuCustom #nav_playlists a,
.pPlaylists h2.pageTitle em.picto { background-position: -130px 0; }
#menuCustom #nav_people a,
.pPeople h2.pageTitle em.picto { background-position: -1570px 0; }
#menuCustom #nav_nlcreas a,
.pNlcreas h2.pageTitle em.picto { background-position: -1957px 0; }
#menuCustom #nav_layouts a,
.pLayouts h2.pageTitle em.picto { background-position: -1435px 0; }
#menuCustom #nav_files a { background-position: -527px 0; }
#menuCustom #nav_projects a,
.pFiles h2.pageTitle em.picto,
.pProjects h2.pageTitle em.picto { background-position: -527px 0; }
#menuCustom #nav_todolists a,
#menuCustom #nav_rooms a,
.pRooms h2.pageTitle em.picto { background-position: -2085px 0; }
#menuCustom #nav_articles a,
.pArticles h2.pageTitle em.picto { background-position: -1174px 0; }
#menuCustom #nav_dates a,
.pDates h2.pageTitle em.picto { background-position: -1045px 0; }
#menuCustom #nav_tickets a,
#menuCustom #nav_ticketssettings a,
.pTickets h2.pageTitle em.picto,
.pTicketssettings h2.pageTitle em.picto { background-position: -915px 0; }
#menuCustom #nav_advpubs a,
.pAdvpubs h2.pageTitle em.picto { background-position: -1694px 0; }
#menuCustom #nav_gallery_categories a,
.pGallery_categories h2.pageTitle em.picto { background-position: -265px 0; }
#menuCustom #nav_bands a,
.pBands h2.pageTitle em.picto { background-position: -5px 0; }
#menuCustom #nav_menus a,
.pMenus h2.pageTitle em.picto { background-position: -2216px 0; }

#menuCustom #nav_maps a,
.pMaps h2.pageTitle em.picto { background-position: -2348px 0; }

#menuCustom #nav_form_creates a,
.pForm_creates h2.pageTitle em.picto { background-position: -2480px 0; }

#menuCustom #nav_publications a:hover,
#menuCustom #nav_pages a:hover {background-position:-784px -132px;}
#menuCustom #nav_highlights a:hover{background-position:-1825px -132px;}
#menuCustom #nav_playlists a:hover{background-position:-130px -132px;}
#menuCustom #nav_people a:hover{background-position:-1570px -132px;}
#menuCustom #nav_nlcreas a:hover{background-position:-1957px -132px;}
#menuCustom #nav_layouts a:hover{background-position:-1435px -132px;}
#menuCustom #nav_files a:hover{background-position:-527px -132px;}
#menuCustom #nav_projects a:hover{background-position:-527px -132px;}
#menuCustom #nav_todolists a:hover{background-position:-1303px -132px;}
#menuCustom #nav_rooms a:hover{background-position:-2085px -132px;}
#menuCustom #nav_articles a:hover{background-position:-1174px -132px;}
#menuCustom #nav_dates a:hover{background-position:-1045px -132px;}
#menuCustom #nav_tickets a:hover,
#menuCustom #nav_ticketssettings a:hover{background-position:-915px -132px;}
#menuCustom #nav_advpubs a:hover{background-position:-1694px -132px;}
#menuCustom #nav_gallery_categories a:hover{background-position:-265px -132px;}
#menuCustom #nav_bands a:hover{background-position:-5px -132px;}
#menuCustom #nav_menus a:hover{background-position:-2216px -132px;}
#menuCustom #nav_maps a:hover{background-position:-2348px -132px;}
#menuCustom #nav_form_creates a:hover{background-position:-2480px -132px;}

.insertMe {   font-size:20px; text-align: center;  width:150px !important	; overflow: hidden;  padding: 0 !important; display: inline-block; cursor: url('/admin/i/dragdropSel.png'), move; text-transform: uppercase; }
.insertMe span {background: $lightoff-color; display: block; padding:5px 0;}
.insertMe em {background: url('/admin/i/insertHere.png') no-repeat center bottom; height: 30px; display: block;}
.navigation > ul > li.insertHere { display: none;}
ul .ui-state-highlight {background: url('/admin/i/insertHere.png') no-repeat center center; width:160px;height: 40px; display:inline-block; border:none; margin: 0 15px;}
.navigation > ul > li.insertHere span { display:none;}
