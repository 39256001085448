@font-face {
  font-family: "ProximaNova";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "ProximaNova-light";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Light.otf");
}

@font-face {
  font-family: "ProximaNova-bold";
  src: url("/admin/fonts/Proxima-Nova/ProximaNova-Bold.otf");
}

#form_modules {
  border: 1px solid #b4b4b4;
  padding: 0;
}

#form_modules > ul {
  background: #97b200;
  margin: 0;
  padding: 0;
  padding-bottom: 40px;
  border: 1px solid #fff;
}

#form_modules > ul::after {
  clear: both;
  content: "";
  display: table;
}

#form_modules > ul li {
  margin: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

#form_modules > ul a {
  display: block;
  height: 70px;
  padding-left: 70px;
  padding-top: 25px;
  text-align: left;
  width: 145px;
  font-weight: bold;
  background-image: url("/admin/i/addStar.png");
  background-repeat: no-repeat;
  background-position: 5px 5px;
  box-sizing: border-box;
}

#form_modules > ul a span {
  color: #ffffff;
  padding-right: 7px;
}

#form_modules > ul a span:before {
  content: 'Ajouter ';
}

#form_modules > ul a:hover {
  color: #000;
}

#form_modules > ul #ModuleVideo a {
  background-image: url("/admin/i/addVideo.png");
}

#form_modules > ul #ModuleAudio a {
  background-image: url("/admin/i/addMp3.png");
}

#form_modules > ul #ModuleEmbed a {
  background-image: url("/admin/i/addCode.png");
}

#form_modules > ul #ModuleLink a {
  background-image: url("/admin/i/addLink.png");
}

#form_modules > ul #ModuleFile a,
#form_modules > ul #ModuleFileManagement a {
  background-image: url("/admin/i/addFile.png");
}

#form_modules > ul #ModuleTilte a,
#form_modules > ul #ModuleText a {
  background-image: url("/admin/i/addText.png");
}

#form_modules > ul #ModuleTextimage a,
#form_modules > ul #ModuleNlitem a {
  background-image: url("/admin/i/addTextimage.png");
}

#form_modules > ul #ModuleGallery a,
#form_modules > ul #ModuleGalleryList a,
#form_modules > ul #ModuleImageDuo a,
#form_modules > ul #ModuleImageTrio a,
#form_modules > ul #ModuleImage a {
  background-image: url("/admin/i/addImage.png");
}

#form_modules > ul #ModuleSearch a {
  background-image: url("/admin/i/addSearch.png");
}

#form_modules > ul #ModuleInclude a {
  background-image: url("/admin/i/addInclude.png");
}

#form_modules > ul #ModulePerson a,
#form_modules > ul #ModulePeopleContact a {
  background-image: url("/admin/i/addPeople.png");
}

#form_modules > ul #ModuleNetwork a {
  background-image: url("/admin/i/addNetwork.png");
}

#form_modules > ul #ModulePlaylist a {
  background-image: url("/admin/i/addPlaylist.png");
}

#form_modules > ul #ModuleBooklet a {
  background-image: url("/admin/i/addBooklet.png");
}

#form_modules > ul #ModuleLikebox a {
  background-image: url("/admin/i/addLikebox.png");
}

#form_modules > ul #ModuleTwitterbox a {
  background-image: url("/admin/i/addTwitterbox.png");
}

#form_modules > ul #ModuleIcal a {
  background-image: url("/admin/i/addIcal.png");
}

#form_modules > ul #ModuleForm a {
  background-image: url("/admin/i/addCheckbox.png");
}

#form_modules .collection-container {
  margin: 15px;
}

#form_modules .form-group {
  padding: 0;
  border-top: 0;
}

#form_modules h2 {
  box-sizing: border-box;
  padding: 5px 5px 5px 40px;
  margin: 0;
  min-height: 35px;
  background-image: url("/admin/i/hachureSelected.png");
  background-color: #ff8a00;
  color: #fff;
  font-weight: normal;
  position: relative;
  font-family: "ProximaNova-light";
  cursor: url("/admin/i/dragdropSel.png"), move;
}

#form_modules h2 .toggle {
  width: 35px;
  line-height: 35px;
  background: rgba(0, 0, 0, 0.5);
}

#form_modules .module-item.closed > div.form-group {
  display: none;
}

#form_modules .module-item.closed > div.form-group.tools-module {
  display: inherit;
}

#form_modules .module-item {
  position: relative;
  border-bottom: 2px dashed #ff8a00;
  margin-bottom: 15px;
}

#form_modules .module-item > div {
  transition: all ease .25s;
}

#form_modules .module-item .fileUploadDel {
  display: none;
}

#form_modules .module-item .form-group {
  padding: 10px;
  background: rgba(0, 0, 0, 0.075);
}

#form_modules .module-item .tools-module {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 0;
  margin: 0;
  background: none;
}

#form_modules .module-item .tools-module::after {
  clear: both;
  content: "";
  display: table;
}

#form_modules .module-item .tools-module ul {
  margin: 0;
  padding: 0;
}

#form_modules .module-item .tools-module li {
  float: left;
  list-style: none;
}

#form_modules .module-item .tools-module li a {
  display: block;
  height: 30px;
  padding-left: 30px;
  font-size: 10px;
  color: #fff;
  line-height: 30px;
  background: no-repeat top left;
  text-decoration: none;
  margin-right: 15px;
}

#form_modules .module-item .tools-module li.first a {
  background-image: url("/admin/i/first.png");
}

#form_modules .module-item .tools-module li.last a {
  background-image: url("/admin/i/last.png");
}

#form_modules .module-item .tools-module li.up a {
  background-image: url("/admin/i/up.png");
}

#form_modules .module-item .tools-module li.down a {
  background-image: url("/admin/i/down.png");
}

#form_modules .module-item .tools-module li.delete a {
  background-image: url("/admin/i/delete.png");
}
