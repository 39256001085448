@charset "UTF-8";
@import "bourbon/bourbon";
@import "partials/sokl/default.scss";
@import "partials/base.scss";
@import "partials/shared/people.scss";
@import "partials/shared/toggle.scss";
@import "partials/sokl/menu_custom.scss";
@import "partials/sokl/graph.scss";
@import "partials/shared/mailjet.scss";
@import "module-sokl";

/* SOKL */
#head a { background: url("/admin/i/sokl-logo.png") no-repeat; height: 76px;}
.boxHome .logoWelcome { background: url('/admin/i/sokl.png') no-repeat;}
.pUnknow #head a { background: url("/admin/i/soklLogin.png") no-repeat; height: 76px;}
.pUnknow #head a { width: 585px; height: 273px; margin: 0 auto; display: block; }

#head span{ display: none; }
#head {padding:5px 0 0 0; height: 125px; width:20%; line-height: 1em; margin-left: 8.0%;}

.pUnknow { background-image: url('/admin/i/bkgAdmin.png'); background-repeat: no-repeat; background-size: cover; background-position: center; background-color: #d0d0d0;}
#welcome li.logout a { background: url('/admin/i/pictoLogout.png') center right no-repeat;padding: 3px 20px 3px 3px;}
#welcome li.logout a:hover { background: url('/admin/i/pictoLogoutHover.png') center right no-repeat; color: $lightoff-color; }


/* Login */
.pUnknow {
  padding-top: 1.5%;
  #footer {
    display: none;
  }
  #head {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
  #header,
  #content {
    background: none;
  }
  .navigationWrapper,
  .menuCustomWrapper {
    display: none;
  }
}
.login {
margin-left:auto;
margin-right:auto;
}
.login > div {
width:360px !important;
}
.loginFormArea input.txt { border-width:15px 15px; border-color: $lightoff-color; border-style: solid; color: $grey-light; font-weight: 300; }

.pBox #content {
width: 100%;
margin: 0;
}

/* popUp */
body.pPopup #content {border-radius: 3px; width:auto; margin-right: 100px; }

#navigation  {
margin:0 2% 0 8.0%;
}
#navigation > ul { height: 34px; overflow: hidden;}
.navigation > ul > li {display:inline-block !important;margin:0 1px 0 0; float: left; }
#navigation ul li a { text-transform:uppercase; font-size:20px;}

#navigation2 {
border-top: 2px solid $white;
height: 34px;
width: auto;
}
#navigation2 > ul {padding:0 2% 0 8.0%;}
#navigation2 > ul > li {display:inline;}
#navigation2 > ul > li a {
background-color: $lighton-color;
border: medium none;
display: block;
line-height: 32px;
text-align: center;
padding:0;
margin-right: 25px;
}

.navigation.navigation3
{
padding-top: 10px;
border-bottom: 2px solid $lighton-color;
}
.navigation.navigation3 > ul {
padding:0 1% 0 0;
margin: 0 3% 4px 8.0%;
height: 32px;
}
.navigation.navigation3 > ul > li a {
border-radius: 0;
height: 31px;
margin-right: 40px;
padding: 0;
line-height: 31px;;
overflow: hidden;
background: none;
}
.navigation.navigation3 > ul > li a:hover,
.navigation.navigation3 > ul > li.sel a,
.navigation.navigation3 > ul > li.active a
{
background: url('/admin/i/arrowMenu3.png') no-repeat center bottom;
padding-bottom: 5px;
font-weight: bold;
}

#navigationAdmin { position:fixed; bottom:0; left:0; width: 100%; padding:13px 0; z-index: 10;}
#navigationAdmin ul { padding:0 3% 0 8.0%}
.navigation.navigationAdmin { transition:all 1s}
.navigation.navigationAdmin:hover { transition:all 1s}
.navigation.navigationAdmin > ul > li a
{
background: none;
color: $nav-admin-color;
margin: 0;
border:none;
}
table td  {font-size:12px;color:$grey;}

#insertForm { display: block; min-height: 550px; border-right: 1px solid #F2F2F1; padding-right: 4px;}

form > .form-group,
.lang-content > .form-group {
  background-image:url('/admin/i/grisHachureEditable.gif');
  border-top: 10px solid #fff;
  padding:15px 0 15px 30px;

}

.form-group{
  @include clearfix;
  > label {
    font-family : $typoLight;
    font-size:18px;
    color:$black;
    display:block;
    width:20%;
    float:left;

  }
  textarea {
    width: 100%;
  }
  > input, > .textarea, .mce-tinymce, .choice-group {
    width:58%;
    margin-left: 1%;
    float:left;
  }
  .choice-group {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 5px;
        input {
          width:auto;
        }
        label {
          width: auto;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
  > input.datePicker,
  > input.timePicker,
  > input.colorPicker { width:25%}
  div.colorTarget { float:left; margin-left: 10px}
  > select, .form-control-static {
    max-width:58%;
    margin-left: 1%;
    float:left;
  }
  .select2 {
    width: 100% !important;
  }
}
.checkbox-resource {
  display: inline-block;
  vertical-align: top;
  background: rgba(#FFF,0.8);
  padding: 10px;
  margin-bottom: 1%;
  margin-left: .5%;
  width: 31%;
  border-radius: 5px;
  box-sizing: border-box;
  height: 130px;
  .nested-item.hide {
    display: none;
  }
  input {
    width: auto;
  }
  label {
    width: auto;
    display: inline-block;
    float: none;
  }
}
.text-danger,
.help-block {
  float: right;
  display: block;
  margin: 0 1% 0 0;
  width: 18%;
  box-sizing: border-box;
  line-height: 1.125em;
  font-size: .86em;
  background: rgba(#fff, .75);
  border-left: 1px solid $grey-light;
  padding:5px;
  cursor: pointer;
  &:hover {
    font-size: 1.5em;
    background: #fff;
  }
}

.text-danger {
  background: $error !important;
  color: $error-bg !important;
}

.subtitle {

  h2.separator {
    font-weight:normal;
    color:#fec14f;
    font-size:1.5em;
    padding:3em 0 0;
    font-weight: normal;
  }
  a {
    color: $lighton-color;
    text-decoration: underline;
  }
}


.inputLong input {font-size:18px;color:#000;}
.inputLong select, .inputLong li em{font-size:16px;color:#000;}
/* hide id field in list */
.admintblist .thId,
.admintblist .tdId { display: none;}



#content {
margin-right:auto;
margin-left:8.0%;
padding:1px 0 10px;
width:80%;
min-height: 400px;
}


h2.pageTitle{font-size:70px; margin: 0 -15.0% 0 -10%; font-family: $typoLight; padding: 40px 0 5px;background-image:url('/admin/i/grisHacheFiltre.png');}
h2.pageTitle em.picto { background-image: url('/admin/i/pictos-sokl.png'); background-repeat:no-repeat; display: block; width:120px; height: 75px; position:absolute; top:35px; left:6%;}
h2.pageTitle a.edit { background: url('/admin/i/pictoEdit.png') right 15px no-repeat; display: inline-block; padding-right: 30px;}
h2.pageTitle span {line-height:65px;padding-left:84px;margin-left:8%; letter-spacing: -0.025em;}

p.error {
  background-image:url('/admin/i/error.png');
}
p.success {
  background-image: url('/admin/i/success.png');
}
p.info {
  background-image: url('/admin/i/success.png');
}

.buttons.buttonsTop { position: relative; top: 40px;}
.buttons.buttonsTop .buttonsInner {		padding-top:5px;		padding-bottom: 70px;		background-image: url("/admin/i/grisHachureEditable.png");		margin-top:10px;		right: -104px;		position:absolute;		text-align: center;		width: 94px;		z-index: 9;
}
.buttonsBot { display: none;}
.edit .buttons.buttonsTop {top:50px}

.btform { background-image: url('/admin/i/btnDefault.png');}
.btsend { display: block; background-image: url('/admin/i/btnSave.png'); }
.btformAdd { display: block;  background-image: url('/admin/i/btnAdd.png'); }
.btformAddbatch { display: block; background-image: url('/admin/i/btnAddBatch.png'); }

.btform.btformExport { background-image:url('/admin/i/btnExport.png');}
.btformExportXlsMac { display: none;}
.btformExportXlsWin { display: none;}
.btformedit {background-image: url("/admin/i/btnEdit.png");}
.btEditGroup {background-image: url("/admin/i/editerSelection.png");}
.btDeleteGroup{background-image: url('/admin/i/btnDeleteSelection.png');}
.submit_and_back{  background-image: url('/admin/i/btnEnregistrerList.png');}
.submit_and_preview{  background-image: url('/admin/i/btnPreview.png');}
.submit_and_add{ background-image: url('/admin/i/btnEnregistrerElement.png');}
.submit_duplicate{background-image: url('/admin/i/btnEnregistrerElement.png');}
.btformdelete{background-image: url('/admin/i/btnDelete.png');}
.btformback{background-image: url('/admin/i/btnBackList.png'); }
.btformOrder{background-image: url('/admin/i/btnOrder.png');}
.btMailtoGroup { background-image: url('/admin/i/btnMailGroup.png'); }
.btsend.submit_code { background-image: url('/admin/i/btnShowCode.png'); }
.btsend.submit_see { background-image: url('/admin/i/btnPreview.png'); }
.btform.btformImport, .btImport,
#btImport { background: url('/admin/i/btnImport.png') no-repeat center top; }
.btsend.btsendEmail { background-image: url('/admin/i/btnSendMsg.png'); }
#filter {background-image: url('/admin/i/grisHacheFiltre.png');}

.actionEdit a {background: url('/admin/i/tdEditer.png') no-repeat center center;display:block; width:23px; height:23px;}
.actionEdit a span {display:none}
.actionDelete a{background: url('/admin/i/tdDelete.png') no-repeat center center;display:block; width:23px; height:23px;}
.actionDelete a span {display:none}

.actionCustom .preview a {background: url('/admin/i/tdCustom.png') no-repeat center center;display:block; width:23px; height:23px;}
.actionCustom .preview a span {display:none}
.actionCustom .actionMore a {
  color:$bt !important;
  display:inline-block;
  padding:2px 6px;
  margin:2px;
  background:$bt-bg;
  border:$bt-border;
  border-radius:$bt-radius;
  text-align: center;
}

.module_edit { border-bottom: 2px dashed $lighton-color; background-image: url('/admin/i/grisHachureEditable.gif'); }
.module_edit:last-child { border-bottom: 2px solid $module-actions; }

.navigation > ul > li > a {
display:block;
text-decoration:none;
font-size: 1.125em;
border: 1px solid rgba(255, 255, 255, 0.25);
background-color: $lightoff-color;
overflow: hidden;
padding-right: 30px;
height:34px;
line-height:28px;
padding-left:15px;
}

.navigation a:hover, .navigation li.sel a, .navigation li.active a {
border-color:rgba(255, 255, 255, 0.25);
}


.navigation1 > ul > li > a:hover,
.navigation1 > ul > li.sel > a,
.navigation1 > ul > li.active > a,
.navigation2  > ul > li > a:hover,
.navigation2  > ul > li.sel a,
.navigation2  > ul > li.active a
{
background-image:url('/admin/i/arrow.png');
background-repeat:no-repeat;
background-position: center bottom;
}

#navigationAdmin > ul > li:first-child a { border:none}
#navigationAdmin > ul > li a
{
background-position: 35px 5px;
background-repeat: no-repeat;
border-left: 1px solid $white;
display: block;
height: 45px;
line-height: 45px;
padding-left: 88px;
}

#nav_home a { background-image: url("/admin/i/pictoHome.png"); }
#nav_viewsite a { background-image: url("/admin/i/pictoViewSite.png"); background-position: 35px 10px !important; }
#nav_menuusers a { background-image: url("/admin/i/pictoUsers.png"); }
#nav_profile a { background-image: url("/admin/i/pictoProfil.png"); }
#nav_menuinfostech a { background-image: url("/admin/i/pictoInfosTech.png"); }

#navigationAdmin > ul > li,
#navigationAdmin > ul > li a { font-size: 18px; }

.btsend,
.btform { display:block; text-decoration: none;cursor:pointer; margin: 5px 0 10px; text-align: center; background-position:center top; background-repeat: no-repeat;}

.btform span,
.btsend.submit_code span,
.btsend.submit_see span { font-size: 13px; font-family: $typo; display: block; padding-top: 55px; }


.btsend span,
.btformAdd span {color:$bt-bg; font-weight: bold; text-align:center; display: block;font-size:16px; padding-top:74px;}

.admintblist td, .admintblist th {font-size:16px; vertical-align: center;}
.admintblist td.tdTitle {font-size:16px; font-family:$typoBold;}

div.mosaic .listElementOver {
background-image: url("/admin/i/grisHachure.png");
background-position: center center;
width:94%;
position:absolute;
display:block;
height:99%;
margin: 0.5%;
z-index: 1;
}
div.mosaic .sel .listElementOver{
background-image: url("/admin/i/hachureSelected.png");
}

#filter { margin:0 -15% 0 -10%;   padding: 5px 2% 0 9.5%;border-top:solid 2px white;}

/* Animation */
/*.messageWrapperIn {
	position:absolute;
	left:0;
	width:100%;
	z-index:10;
}

*/
.messageWrapper {
margin:0 -15% 0 -10%;
padding: 0;
box-sizing: border-box;
text-align: center;
}
.messageWrapper .message.off {
padding-top:25px;
padding-bottom:25px;
}

.messageWrapper .message {
  font-size:18px;
  padding-top:125px;
  padding-bottom:125px;
  visibility: visible;
  line-height: 1.25em;
  a {
    text-decoration: underline;
    color: $lighton-color;
  }
}
@media only screen and (max-width: 1400px) {		#head { margin-left: 6%; }
}

@media only screen and (max-width: 1300px) {		#head { margin-left: 3%; }
}
@media only screen and (max-width: 1200px) {		#head { margin-left: 0.5%; width:21%;}		#head a {background-size: contain;}		#welcome .date { display: none;}
}

.nested {
  padding-left: 20px;
}

.module-item .select2-container {
  max-width: 100% !important;
}


.admintblist.newsletter {
  .process {
    display: none;
  }
}

.choice-group {
  .info-global {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
  }
}

.preview {
  position: absolute;
  top: 1%;
  height: 98%;
  left: 2%;
  width: 96%;
  background-color: white;
  z-index: 1000;
  overflow: hidden;

  a.remove {
    position: absolute;
    right: 9px;
    top: 9px;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    height: calc(100% - 30px);
  }
  ul {
    width: 100%;
    background: #ff8a00;
    height: 40px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 15px;
      background: #feb734;
      height: 40px;
      vertical-align:  top;
      box-sizing: border-box;
      text-transform: uppercase;
      &:hover {
        a {
          color: #7d0606;
        }
      }
      a {
        color: white;
      }
    }
  }
}

.cover-preview {
  background: rgba(#000, .8);
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  z-index: 900;
}

.task {
  position: fixed;
  top: 0;
  left: 50%;
  background: $white;
  text-align: center;
  z-index: 1000;
  width: 50%;
  display: none;
  &.active {
    display: block;
  }
  @include transform(translateX(-50%));
  box-shadow: 1px 1px 12px #555;
  .title {
    background: $lightoff-color;
    position: relative;
    a {
      position: absolute;
      top: 50%;
      right: 10px;
      color: $white;
      font-weight: bold;
      &:hover {
        color: $black;
      }
      @include transform(translateY(-50%))
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 18px;
    padding: 10px;
    color: $white;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    margin: 0;
    list-style: none;
    text-align: left;
    border-bottom: 1px dashed $grey;
    min-height: 70px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    .content {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      .item {
        position: absolute;
        top: 50%;
        left: 0;
        padding: 10px;
        box-sizing: border-box;
        z-index:2;
        width: 100%;
        @include transform(translateY(-50%));
        .pourcent {
          position: absolute;
          right: 10px;
        }
      }
    }

    .cover {
      background: $bt-bg;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }
  }
}

.popup-selection {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  min-height: 200px;
  padding: 0 15px;
  box-sizing: border-box;
  background-image: url(/admin/i/grisHachureEditable.gif);
  box-shadow: 1px 1px 12px #555;
  @include transform(translate(-50%, -50%));
  display: none;
  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    color: white;
  }

  .remove {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #feb734;
    @include clearfix();
    a {
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
    }
  }

  .category {
    position: absolute;
    top: 56%;
    left: 50%;
    width: 90%;
    @include transform(translate(-50%, -50%))
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }

  button {
    background-color: #97b200;
    display: block;
    border: none;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: 30%;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 15px;
    cursor: pointer;
    outline: none;
  }

  button:disabled,
  button[disabled]{
    opacity: 0.2;
    cursor:no-drop;
  }
}

.img_source {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 20000;
  height: calc(100% - 58px);
  img {
    max-width: 100%;
  }
  .tooltip {
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 1px 1px 12px #555;
    a {
      display: inline-block;
    }
  }
}
.mce-flow-layout-item.mce-last {
  display: none;
}

.mce-container-body .mce-resizehandle {
  display: none;
}