.center {
text-align:center;
}

ol {
padding:10px;
margin:0;
}

caption {
padding:4px;
}

/* Common */
#content .module_edit h3 { margin: 1em 0 0 0; background-image: none; background-color: $module-bg; line-height: 2.25em; padding-left: 1em; }
.module_unlink,
#content .module_edit h3,
#content .moduleEditOrder a span { color: $module-title !important; }

/* Login */

.loginFormArea {
	padding:0 20px; 
	margin: 10px 0;
	.introLogin { font-size: 19px; color: $grey; font-weight: 300; text-align: center; }
	form { text-align: center; width: 400px; margin: 0 auto; }
	p.plogin {margin: 10px 0;}
	p.ppassword {margin: 0 0 2px;}
	p.optin { background:rgba(#000, .025); margin: 0 0 10px;  padding: 5px 15px; text-align: left; font-size: 0.85em;}
	p.optin input { border: 1px solid $grey; }
	p.optin label { font-size: 16px; color: $grey; font-weight: 300; }
	label[for="username"],
	label[for="password"] { display: none; }
	input.txt { border:1px solid $grey;  width:100%; padding:5px 10px; font-size: 1.5em; box-sizing: border-box;}
	.ppassword .lost {font-size: 0.85em; display: none; }
	.submit{ height: 88px; margin: 0 auto;  box-sizing: border-box;}
	.btLogin { width:100%; padding:15px 0; background: $grey; color: $white; font-size: 2em; line-height: 1em; font-weight: 600; text-transform: uppercase; text-decoration: none; box-sizing: border-box; display: block;}
}

.date td a {
display:inline-block;
padding:3px 8px;
}

.date th, .date td {
text-align:center;
padding:3px;
}

#itemByPage{border:solid 1px $grey-light; color:$filter-input;}


/* Typography */
h1, h2, h3, h4, h5, h6 {
margin:0;
padding:0;
font-family: $typoBold;
}

a:hover {
text-decoration:none;
}

a.edit, a.delete {
padding-left:25px;
background-repeat:no-repeat;
background-position:left center;
}
a {

}

/* Table styling */

table {
width:100%;
}

tr{border-top:6px;}

th, td {
padding:0px 15px;
text-align:left;
vertical-align:top;
}

/* Header */
#header {
  background: $header-bg;
  .spaces {
    z-index:100;
    position: absolute;
    right: 0;
    top:0;
    color: #fff;
    padding: 5px 5px 5px 15px;
    margin-right: 3%;
    background-color: $lighton-color;
    select {
      border: none;
      background-color: rgba(#fff, .33);
      option {
        border: solid #fff;
        border-width: 0 0 1px;
      }
    }
  }
}

#head {
  border:0;
  margin:0;
  color:$header;
  a {
    display: block;
    margin-top: 10px;
  }
}

#welcome { position: absolute; right:0; top:15px; margin-right: 3%; width:10%}
#welcome .dayname { text-transform: capitalize;}
#welcome ul { padding:0; margin:0;}
#welcome li { list-style: none; display: block; text-align: right;padding:0; margin:3px 0;}
#welcome li.userName { font-size: 20px; }
#welcome li .name { display: none;}
#welcome li.date { font-family: $typoLight; font-size: 16px; color: $nav-admin-color; }
#welcome li.date .year {display: none;}
#welcome li.logout a { color: $nav-admin-color; font-family: $typoBold; display: block; float: right; }

/* Nagivation */
.navigation {margin:0 auto 0 auto;padding:0;width:auto;}
.navigation ul  {margin: 0;padding: 0;}

.navigation > ul > li {position: relative; display:inline-block;}
#navigation > ul > li { float:none}



.navigation li.sepa { width:5px; display: inline-block;}

.navigation2 > ul > li a {
display:inline;
}

.navigation.navigation3 {
background-color:$nav3-bg;
}
.navigation.navigation3 > ul > li a {
color: $nav3;
}



#foot {
width:92%;
padding:5px 20px;
margin:0 auto 10px;
border-bottom-right-radius: 10px;
}
.navigation2 a
{
border-top-left-radius:5px;
border-top-right-radius:5px;
}

/* Forms */
small {
font-size:12px;
font-weight:normal;
padding-left:10px;
}

input, textarea, select{
  border:1px solid;
  font-family:inherit;
  font-size:inherit;
  padding:4px;
  max-width: 100%;
}
select {
padding-top:3px;
padding-bottom:3px;
}
input.file { padding:4px 0;}
input[type="submit"], input[type="reset"] {
cursor:pointer;
}

/* Error and Success messages */
.error, .success {
padding:4px 8px;
}
span.msg { display:block; margin:0.33em 0; padding:5px; border-top:1px solid $white}
span.msg:first-child {border:none}
/* Pagination */
.pagination {
text-align:right;
}
.pagination a, .pagination span {
display:inline-block;
margin:0 2px;
padding:2px 6px;
}

/* CSS Document */
body {margin:0; padding:0}
table {font-size:inherit;border-collapse:collapse;border-spacing:0;}
h2 {font-weight:normal; font-size:1.3em; padding:0; margin-bottom: 0.25em;}
h2.pageTitle{color:$title-color; font-weight:normal; position:relative}
h2.pageTitle span {display:block; }

h2.pageTitle a.edit {font-size: 16px; letter-spacing: 0; text-decoration: underline;}
h2.pageTitle a.edit { font-size: 18px !important; }
th { font-weight: normal}
img {border:none;}
.sep, .cb {clear:both; display: block;}
em.empy { color:$grey-light;}

body {font-family : $typo; color:$body-color; background:$body-bg; font-size: 14px;}
a {color:$link-color;  text-decoration:none;outline-style:none;}
a:hover {color: $lighton-color}
.page-wrap {  position: relative; min-width: 800px; }
input, select, textarea {border:none;}
/*
---------------------
MSG
---------------------
*/
.msg p { margin:0 0 0.5em}
.msg div { margin-bottom:10px; padding:5px 10px; color:#fff; border:1px solid}
.msg div.wrong {background:$error;}
.msg div.ok { background:#7EBA3F;  border-color: #468106;}
.msg div.ok p, #bloc .msg div.ok p, .msg div.wrong p, #bloc .msg div.wrong p {color:#fff;}
.msg div h5 { font-size:1.25em; padding:0; margin:0 0 0.25em;}
.msg div p a { color:#113;}

.messageWrapper {margin-bottom: 10px;}
.messageWrapper .message { transition: all ease 2s}
p.success, p.error, p.warning { display: block;}
p.success, p.error, p.warning { margin:1px 0; padding:5px 3%;}
p.error { 
	 color:$error;
	 background-color: $error-bg;
	 text-align:center;
 }
p.warning {color:#fff;}
p.success
{
	text-align:center;
	text-transform:uppercase;
	color:$success;
	background-color: $success-bg;
	font-weight:bold;
}
p.success a {color:$success}
div.avert,
p.info {
	text-transform:uppercase;
	color:$info;
	background-color: $info-bg;
	font-weight:bold;
	padding:8px;
	margin: 20px 0;
}
#navigation li#nav_menuhome a {background-image:url('/admin/i/home.gif'); background-position:center -25px; background-repeat:no-repeat;  width:20px;}
#navigation li#nav_menuhome a span { visibility:hidden;}

#footer {text-align:center; padding: 15px 0 95px; font-size: 2em; color:rgba(0,0,0,0.1)}
#footer p { margin: 0;}

.pBox #footer p { display: none; }

#footer a {text-decoration:none; color:rgba(0,0,0,0.2)}
#footer a:hover {text-decoration:none; }

.navigation.navigationAdmin { background-color: $nav-admin-color-bg;}
.navigation.navigationAdmin:hover { background-color: $nav-admin-color-bg-hover; }
.navigationAdmin .userName { padding-right: 5px; display: inline-block;}
.navigationAdmin .userName .name { text-transform: uppercase;}

.navnested2 { display: inline;}
.navnested2 ul { display: inline; margin: 0; padding: 0;}
.navnested2 ul li { display: inline;  margin: 0; padding: 0;}

#bottom {text-align: center}

.bt {cursor:pointer;}
.btsend, .btform { text-decoration: none; cursor:pointer; border-radius: $bt-radius;}

.msg .btsend, .msg .btform { margin: 5px; color:#404040}


.btsend.submit_code span,
.btsend.submit_see span { font-weight: normal; color: $grey !important; }
a.btsend.submit_code:hover span,
a.btsend.submit_see:hover span { color: $lighton-color !important; }

.pGallerycat .actionadd a,
a.bt,
label.bt   { color:$bt !important; display:block; padding:2px 6px; margin:2px 6px 2px 0; background:$bt-bg; border:$bt-border; border-radius:$bt-radius; min-width: 180px; text-align: center; text-decoration: none !important }
a.btCancel,
label.btCancel { color:$bt-cancel; background-color: $bt-cancel-bg; border:$bt-cancel-border; border-radius:$bt-cancel-radius;}
a.btDelete,
label.btDelete { color:$bt-del; background-color: $bt-del-bg; border:$bt-del-border; border-radius:$bt-del-radius; display: inline-block;}

#wrap {margin:0; opacity: 1; transition: all ease 2s;}
#wrap.on { opacity: 0;}
#wrap .wrap-content { padding:10px 30px; background:#f5f5f5}
#wrap .wrap-content h1 {padding:0 0 0.1em 0; margin:1em 0 0 0; color:#402B0D; border-bottom:1px solid #402B0D; font-size:1.8em}
#wrap .wrap-content th, #wrap .wrap-content td { padding:3px 12px; border:1px solid #fff; background:#e2e2e2}
#wrap .wrap-content th { font-weight:bold}

#filter h2 { color:$grey; padding:0; margin:0 4px 2px; font-size:1em; font-weight:bold; display: none;}
#filter form > ul {list-style-type: none; display: block; margin: 0; padding:0;}
#filter form > ul > li {display:block; float:left; margin:0 5px 5px 0; padding:0; list-style: none;}
#filter .filter_reset { white-space:nowrap; font-weight:bold; color:$error; margin:17px 0 0 10px;}
#filter .filter_reset:before { content:'✖ '; font-size:19px;}
#filter .filter_reset a { color:$error; font-size:0.9em; font-weight:normal;position:relative; bottom:2px;}
#filter li.filter_sel > span > label {display:block; width:100%; overflow:hidden; white-space: nowrap; line-height:16px;}
#filter li.filter_sel > span { display:inline-block;  font-size:0.85em; padding:3px; color:#663; margin-bottom:3px; min-height: 40px;}
#filter li.filter_sel select {min-width:160px; margin: 0;}
#filter li.filter_text > span > label {display:block; width:90px; overflow:hidden; white-space: nowrap; line-height:16px;}
#filter li.filter_text > span { display:inline-block; font-size:0.85em; padding:3px; color:#663; margin-bottom:3px; min-height: 40px}
#filter li.filter_text > span > input { padding:4px 4px; margin:0; width:160px; color:$filter-input;}
//#filter li.filter_text > span > a { display:inline-block; background-color:$bt-bg; color:$bt; padding:4px;}
#filter li button { display:inline-block; background-color:$bt-bg; color:$bt; padding:4px;
  cursor: pointer;
  border: none;
}
#filter li.submit {
  margin: 13px 5px 5px 0;
}
#filter li#filter_li_fullname > span > input { padding-left: 20px; background:#fff url(/admin/i/search.gif) no-repeat 1px center !important;}
.in ul label{color:$filter-label;}
.in ul select{color:$filter-input;}


#filter_button { padding:4px 0;}
#filter_button ul {list-style-type: none; display: block; margin: 0; padding:0;}
#filter_button ul li.filterButtonTitle span,
#filter_button ul li.filterButtonEnd span {display:block; padding:4px 8px; color:#999; background:#eee; border:1px solid #eee; border-radius:8px 0 0 8px;}
#filter_button ul li.filterButtonEnd span {border-radius:0 8px 8px 0;}
#filter_button ul li.filterButtonTitle label { margin:0; padding:0} 
#filter_button li {display:block; margin:0 6px 0 0; padding:0; float:left}
#filter_button li a { display:block; padding:4px 8px; color:#fff; background:$lighton-color; border:1px solid $lighton-color;}
#filter_button li.sel a,
#filter_button li.sel a:hover { background:#fff; color:#069; text-decoration:none; border:1px solid #069;}
#filter_button li a:hover { background:#59cbfd; color:#fff; text-decoration:none;}

.adminSearch {background:#FFFFCC; border:1px solid $lightoff-color; padding:4px; margin:2px 0;}
.adminSearch h2 { color:#C19C00; padding:0; margin:0; font-size:1em; font-weight:bold;}
.adminSearch label {float:left; padding:2px; display:block; text-transform:capitalize;}
.adminSearch span input { width:200px;}
.adminSearch span { display:inline-block; background-color:#ffa; border:1px solid #cc6; border-radius:3px; font-size:0.85em; padding:3px; color:#663; margin-right:4px;}
.adminSearch span input { height:12px; margin:0 3px}
.adminSearch span a { display:inline-block; background-color:#C19C00; border-radius:3px; color:#fff; padding:3px;}

.listIsEmpty strong { font-weight: bold; margin: 1em 0; display: block; font-size: 1.25em;}
.admintblist #listForm {overflow: auto;}
.admintblist td span.protected,
span.protected { display:inline-block;;width:20px; height:17px; background: url(/admin/i/lock.gif) no-repeat center top;}
.admintblist td a.OnOff { display:block;; line-height:17px; background: no-repeat left top;padding-left: 20px;}
.admintblist td a.OnOff0,
.admintblist td a.OnOffno,
.admintblist td a.OnOff1:hover,
.admintblist td a.OnOffyes:hover {background-image: url(/admin/i/off.gif)}
.admintblist td a.OnOff1,
.admintblist td a.OnOffyes,
.admintblist td a.OnOff0:hover,
.admintblist td a.OnOffno:hover{background-image: url(/admin/i/on.gif)}
.admintblist td a.OnOff:hover { text-decoration: none;}
.admintblist th {font-family:$typoBold; color:$th; background:$th-bg; padding:4px 10px;}
.admintblist td {color:$td; padding:5px 10px}
.admintblist th	a {color:white;font-family:$typoBold; color:$th}
.admintblist td	a {}
.admintblist td.cell1 {background:$td-bg;}
.admintblist td.cell2 {background:$td-bg2;}
.admintblist th.selected {background:rgba($th-bg, .66);}
.admintblist th.sort-ASC a:before { content:'↓'}
.admintblist th.sort-DESC a:before { content:'↑'}
.admintblist td.selected1 {background:rgba($td-bg, .66);}
.admintblist td.selected2 {background:rgba($td-bg2, .66);}
.admintblist .action.deactivate { display: none;}
.admintblist td img {max-height:90px}
.firstline{border-bottom:solid 10px white;} 
.lastline{border-bottom:solid 10px white;}
.firstline th{border:1px solid white;}
.sStatusPublish{color:$status-publish}
.sStatusDraft{color:$status-draft;}
.sStatusArchive{color:$status-archive;}

.editTable { width: 100%; }
.editTable .separator {  }
.editTable > tbody > tr > td, .editTable > tbody > tr > th {border-top:1px solid #fff; padding:6px 4px; font-size:12px;}
.editTable td > a,
.editTable td > ul li a {}
.editTable td.label {width:20%; vertical-align:top;}
.editTable td.label .legend { display:block; font-size:10px; color:#666666; padding-top:2px;}
.editTable td .fieldname { font-size:10px; color:#666666; padding-top:2px; font-style:normal; display: none; padding:1px 3px; background: #fff; border-radius: 3px; margin-left: 3px;}
.pGod .editTable td .show .fieldname { display: inline-block;}
.editTable td.inputLong input.text, .editTable td.inputLong select, .editTable td.inputLong textarea, .editTable td textarea {width:98%;}
.editTable td textarea {width:98%; min-height:90px;}
.editTable td.inputLong input.text, .editTable td.inputLong select, .editTable td.input inputnput.text, .editTable td textarea {width:97%; border:none; background:#fff; padding:5px 0; padding-left: 1%;}
.editTable td.inputLong input.text.disabled, .editTable td.input inputnput.text.disabled { background: #e5e5e5;}

.editTable td.inputLong .tiny-zone {width:98%;}

#form_group_alias,
.form-group-classname {
  label {
    opacity:.5;
  }
  input {
    background: #eee;
    color: #888;
  }
}

.editTable td.input select {border:1px solid #999; background:#F6FFD4}
.editTable td.input input.medium {width:50%}
.editTable td.info {color:#666; font-size:11px; width:30%; vertical-align:top;}

td.inputDouble { background-image: none !important; padding-top: 0 !important; }

.editTable tr.line1 .inputDouble,
.editTable tr.line2 .inputDouble { padding: 0; }

.inputDouble .editTable tr.line1 td.label,
.inputDouble .editTable tr.line2 td.label { background-image: none !important; }


.editInPlace { display: inline-block; border:1px solid transparent; border-bottom-color:#ddd; padding:3px;  line-height: 1em; position: relative; margin-right: 5px;}
.editInPlace:hover { background: #fff; border-color:$lighton-color;}
.editInPlace a.btEditInPlace {display: inline-block; padding:5px; margin-left: 2px;}
.editInPlace a.btEditCancel { display: inline-block; border:0; padding:5px; text-align: center; color:$bt-cancel; background-color: $bt-cancel-bg; border:$bt-cancel-border; border-radius:$bt-cancel-radius; box-sizing: border-box;}


.fileUploadDel input {visibility: hidden; position: absolute; left:-50px;}
.fileUploadDel.sel label { opacity: 0.5;}
.fileUploadDel label em{display:none}
.fileUploadDel.sel label em{display:block; font-size: 0.85em;}

.fileUpload {
  p.imageSize {
    margin: 0;
    padding: 0;
    font-size: 0.85em;
    em,
    strong {
      font-weight: normal;
      font-style: normal;
    }
  }
  img {
    max-height: 120px;
    box-shadow:0 2px 5px #999;
  }
}
.currentFile {
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  margin: 0 0 5px;
  clear: both;
  margin-left: 21%;
  max-width: 49%;
  a {
    text-decoration: underline;
  }
}
.fileUpload,
.fileUploadDel {
  margin-left: 21%;
}


.listIsEmpty .btformAdd { display: block; width:70px; text-align: center;}
/*
-----------------------------
POPUP Mode
-----------------------------
*/
body.pPopup {}
body.pPopup h1#head { display: none;}
body.pPopup #footer { display: none;}
body.pPopup #content { padding: 10px; margin: 10px; width:auto;}
body.pPopup h2.pageTitle { display: none;}
/*
-----------------------------
VIEW MODE
-----------------------------
*/
body.modeView input.file,
body.modeView div.fileStyle,
body.modeView .tableFileUploadDel,
body.modeView .tableFileUpload .imageSize,
body.modeView .browseTable label,
body.modeView .browseTable input,
body.modeView .browseTable input,
body.modeView .editTable td.info,
body.modeView .ui-selectmenu,
body.modeView .module_add,
body.modeView .moduleEditOrder,
body.modeView .module_unlink_container
{ display: none;}

.valToText { color:#222; display: block;}
.valToText_inputtext,
.valToText_select {font-weight: bold;}
.valToText .empy { font-weight: normal;}
.valToText_textarea {white-space: pre-wrap;}


body.modeView .buttons .btform,
body.modeView .buttons .btsend { display: none;}
body.modeView .buttons .btform.btformback { display: block;}
body.modeView .buttons .btform.btformprint { display: block;}
body.modeView .buttons .btform.btformedit { display: block;}
body.modeView .buttons .btsend.btformedit { display: block;}


#previewDiv { background:#fff; border:2px dotted #999; margin:8px}

#bottom {padding:4px; color:#fff; position:relative}
#bottom p {padding:0; margin:0}
#bottom p a {text-decoration:none}
#bottom-context { position:absolute; top:0; right:0; padding:5px 2% 0 0; text-transform:uppercase; color:#CF892D; font-family:Georgia, "Times New Roman", Times, serif}

.searchblock { border:2px solid #91E800; padding:2px; margin-bottom:2px; background:#D1FFBE}
.searchblock a {color:#0033CC;}
.searchline { border-top:1px solid #91E800; padding:4px 4px 8px; clear:both;}
.searchline h4 { font-size:11px; padding:0; margin:0; width:150px;}
.searchline input, .searchline select { margin:0;}
.searchline span {display:block; padding-bottom:2px;}
.searchline span select { width:80px; font-size:10px;}
.searchline .field {float:left; padding:2px 8px 2px 2px;}
.searchline .field label, .searchline .field_ label {}
.searchline .field_ {float:left;  padding:2px 8px 2px 2px;}
.searchline .field_ label {color:#999;}
.searchline .field input.text { background:#ffc; border:1px solid #666;}
.searchline .field_ input.text { background:#D1FFBE; border:1px solid #999}
.searchline .cboolean, .searchline .ctriple { width:200px; height:60px;}
.searchline .field_, .searchline .field { border:1px solid #fff;}
.searchline .field , .searchline .field_  { margin:5px 5px 0 0;}
.searchline .level {border-bottom:2px solid #fff; padding-bottom:5px; padding-left:40px; clear:both;}
.searchline .level_ {display:none;}
.searchline em { display:block; font-size:10px; color:#003300; white-space:normal;}

.query-save { border-top:4px solid #666633; border-bottom:4px solid #666633; background-color:#FFFF99; padding:8px;}
.query-save h2 {margin:0; padding:0 0 5px; color:#666633}
.query-save input.text {width:340px; border:1px solid #999; background:#F6FFD4; padding:5px 3px}

.editTable .tagAdd { padding-bottom:8px; border-bottom:2px dotted #999;}
.editTable .tagAdd strong { display:block;}
.editTable .inputLong .tagAdd input.text,
.editTable .input .tagAdd input.text {width:250px; margin-right:10px;}
.editTable .addlist_tag {margin:0; padding:0; list-style:none; background:#fff;}
.editTable .addlist_tag li {margin:0; padding:0;}
.editTable .addlist_tag li label { display:inline;}
.editTable .addlist_tag li.sepaddlist { border-top:1px solid #999; padding-top:5px; margin-top:5px}

.avert li {font-size:12px;}
.update h2, .avert h2, .update h5, .avert h5 {margin:0; padding:0;color:$white; font-weight:bold;}
.update a,
.avert a.bt {background:$bt-bg; color:$bt; display:inline-block;}

.avert a {color:$white;}
.editTable #id_addlist_prx_articles2tag {max-height:400px;overflow:auto;}
.editTable #id_addlist_prx_articles2tag li { display:block; margin:0; padding:0; float:left; width:160px; height:20px; overflow:hidden; line-height:20px;}

p.withSelected { padding-left:30px; background:url(/admin/i/withselected.gif) 8px 0 no-repeat; margin-top: 5px; clear: both;display:none;}

.progress {display: block; background:$white; border: $grey-light 1px solid; height:10px; width: 90%; display: block; margin:5px 0;}
.progress span { display: block;  height:10px; background: $grey url(/admin/i/loading.gif); width:0}
.progress span em{ display: none;}

.adminPagesList { 
	margin:15px 0 10px;
	
	ul.pagination {
		padding: 0;
		display: inline-block;
		vertical-align: middle;
		li {
			display: inline-block;
			list-style: none;
			&.active {
				
			}
			&.page {
				&:before {
					content: "•";
				}
			}
			&.page1 {
				&:before {
					content: "";
				}
			}
			&.disable {
				opacity: .5;
			}
			a {
				color:$lighton-color;
			}
		}
	}
	p.totals {
		padding: 0;
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		color:$grey-light;
	}
}


/*
---------------------
MARK
---------------------
*/
.starsMark { width:132px}
span.starsMark {  background-position:18px -1px !important}
span.stars_unset {background:url(/admin/i/stars0.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_0 {background:url(/admin/i/stars0.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_1 {background:url(/admin/i/stars1.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_2 {background:url(/admin/i/stars2.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_3 {background:url(/admin/i/stars3.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_4 {background:url(/admin/i/stars4.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_5 {background:url(/admin/i/stars5.png) no-repeat 0 -1px; display:block; width:132px; height:20px;}
span.stars_0 em.stsZero { background:$lightoff-color}
em.stsZero { text-align: center; font-style:normal; border-radius:8px; height:18px !important; margin-top:1px}
em.stsReset { text-align: center; font-style:normal; border-radius:8px; height:18px !important; margin-top:1px}
/*
---------------------
GMAP
---------------------
*/
.gMap { height: 375px;}
.gmapButtons a,
.editTable td .gmapButtons a{ text-decoration:none; text-align:center; color:$bt; background:$bt-bg; border:$bt-border; border-radius:$bt-radius;}
/*
---------------------
LANG
---------------------
*/
.tabLang .sel {font-weight:bold; background:$white;  border-bottom:1px solid $white}
.tabLang { height:21px; margin:0; padding:0 0 0 5px; border-bottom:1px solid $grey-light}
.tabLang li { height:20px; margin:0; padding:0; line-height:20px; float:left; border:1px solid $grey-light; margin:0 3px 0 0; padding:0 8px; list-style:none; cursor:pointer;}
.panelLang {background:$white; padding:5px; border-color:$grey-light; border-style:solid; border-width:0 1px 1px 1px}


.ui-button.ui-state-default .ui-icon-customcheck {background-image: url(/admin/i/customcheckbox_.gif); font-weight: normal !important;}
.ui-button.ui-state-default .ui-button-text {font-weight: normal !important;}
.tableFileUploadDel .ui-button.ui-state-default .ui-button-text {color:$grey-light; font-size: 12px;}
.tableFileUploadDel .ui-button.ui-state-default.ui-state-active .ui-button-text {color:#333;}
.ui-button.ui-state-default.ui-state-active .ui-icon-customcheck {background-image: url(/admin/i/customcheckbox.gif);}
.ui-selectmenu .ui-selectmenu-icon { border-left:1px solid #ccc; height: 20px; width:20px}
.ui-selectmenu,
.ui-selectmenu-menu {font-size: 12px !important;}
.ui-selectmenu { padding-right: 20px;}
.ui-selectmenu-menu li a,
.ui-selectmenu-status { padding: 0.205em 2.1em 0.205em 1em !important;}
.ui-selectmenu {height: auto !important;}
.ui-selectmenu-dropdown .ui-selectmenu-icon {
background-image:url(/admin/i/customselect.gif) !important;
background-position: 2px 2px !important;
}
.ui-selectmenu-dropdown.ui-state-active .ui-selectmenu-icon,
.ui-selectmenu-dropdown.ui-state-hover .ui-selectmenu-icon {
background-image:url(/admin/i/customselect_.gif) !important;
}
.ui-selectmenu-open {z-index:3000 !important}
.ui-state-active {font-weight:inherit;}
.ui-state-active a {color:inherit !important;}


.navigation {background: $nav-bg;}
.navigation2 { background: $nav2-bg;}
.navigation3 {background:$nav3-bg;}

.navigation li a {color:$nav;}
.navigation1 > ul > li > a:hover,
.navigation1 > ul > li.sel > a,
.navigation1 > ul > li.active > a{
background-color:$lighton-color;
color: $lighton-over-color;
}

.navigation2 > ul > li > a:hover,
.navigation2 > ul > li.sel > a,
.navigation2 > ul > li.active > a{
background-color:$lighton-color;
color: $lighton-over-color;
}
.navigation.navigation3 > ul > li.sel a,
.navigation.navigation3 > ul > li.active a
{
color: $lighton-color;
}


#navigationAdmin > ul > li a:hover,
#navigationAdmin > ul > li.sel a,
#navigationAdmin > ul > li.active a
{
color:$lighton-color;
}


/* Mosaic */
.mosaic {
  .admintblist {
    text-align: center;
  }
  .firstline,
  .mosaic .lastline {
    display: none;
  }
  blockquote {
    border: 1px solid $grey-light;
    vertical-align: top;
    display: inline-block;
    width: 170px;
    margin: 0 8px 16px;
    overflow: hidden;
    position: relative;
    min-height: 145px;
    text-align: left;
    text-align: center;

    &:hover {
      border-color: $white;
      .listElementOver {
        display: none;
      }
    }
    > span {
      display: block;
      font-size: 0.85em;
      padding: 1px 2.5%;
      clear: both
    }
    > span.action {
      display: inline-block;
    }
    > span .d,
    > span .m {
      color: $lighton-color;
      font-size: 1.6em;
    }
    > span .d em,
    > span .m em {
      display: none;
    }
    > span .y {
      margin-left: 4px;
      color: $grey-light;
    }
    > span .d {
      margin-right: 2px;
    }
    > span .chM {
      position: absolute;
      top: 0;
      left: -2px;
    }
    span img {
      box-shadow: 0px 0px 3px $grey;
      max-width: 100%
    }
    span .actionEdit,
    .spanEdit {
      display: none !important;
    }
    .sImage {
      position: relative;
      display: block;
      margin-bottom: 2px;
      min-height: 90px;
      max-height: 140px;
      background: $black;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
      }
    }
    /*
    .actions {
      background: $white;
      margin: 0 auto;
      height: 29px;
      position: absolute;
      z-index: 1;
    }
    .actions > span {
      float:left;
      width:25px;
      margin-top:2px;
      height: 25px;
      display: block;
      overflow: hidden;
    }
    .actions > span.chM {text-align:center; margin-right: 4px; border-right: 1px solid $grey-light;}

    */

    .actions {
      text-align: center;
      background: #fff;
      margin-top: 3px;
      margin-bottom: -3px;
      > span {
        display: inline-block;
        box-sizing: border-box;
        padding: 1px;
        vertical-align: top;
      }
      span.chM {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid $grey-light;
        line-height: 25px;
      }
    }
    > span.spanTitle,
    > span.spanName {
      color:$lighton-color;
      font-size:1.125em;
      text-transform:uppercase;
      font-family: $typoBold;
      letter-spacing: -.05em;
    }
    .fdate{
      font-size:1.25em;
      text-transform: uppercase;
      color:$grey-light;
      text-align: center;
      display: block;
    }
    .fdate .d, .fdate .m{
      font-family:$typoBold;
      font-size:26px;
    }
    .spanId { display: none;}

  }
}
.listMenuMode {position:relative}
.listMenuMode div {position:absolute; top:10px; right: 0;}
.btMosaic { font-weight: bold; display: inline-block; padding:0 40px;margin-right:15px;}
.list .listMenuMode .btList { font-weight: bold; display: inline-block; padding:0 10px 0 38px;}
.btList{ font-weight:bold;}
.listMenuMode a {padding-left:38px;display:block;float:left;line-height:22px;}
div.mosaic div.listMenuMode > div > a.btMosaic{color:$lighton-color;background-image:url('/admin/i/mozaique.png');background-repeat:no-repeat; width:32px; height:22px;}
div.list div.listMenuMode > div > a.btMosaic{color:$grey-light;background-image:url('/admin/i/mozaique2.png');background-repeat:no-repeat; width:32px; height:22px;}
div.mosaic div.listMenuMode > div > a.btList{color:$grey-light;background-image:url('/admin/i/liste.png'); background-repeat:no-repeat; width:60px; height:22px;}
div.list div.listMenuMode > div > a.btList{color:$lighton-color;background-image:url('/admin/i/list2.png'); background-repeat:no-repeat; width:60px; height:22px;}


/* Order */
.orderIt #tblist blockquote,
.orderIt #tblist tr {cursor: url('/admin/i/dragdropSel.png'), move;}

/* Custom dashboard */
.custom_dashboard
{
  clear: both;
  padding: 15px 20px;
  margin: 20px 0;
  border : $grey-light solid;
  border-width: 1px 0;
}
.custom_dashboard .buttons
{
  text-align: left;
}
/* Box */
.boxHome { text-align: center; font-size: 2em; color:rgba(0,0,0,0.25);}
.boxHome .userName { display: none; }
.boxHome .logoWelcome { background-repeat: no-repeat; width: 669px; height: 404px; margin: 30px auto 30px; }

.boxInfo {float:left; width:32%; margin:2% 2% 2% 0;}
.boxInfo:nth-child(3n) { margin-right: 0;}
.boxInfo .box {min-height: 140px;}
.box {font-size:13px;margin:0;position:relative;border:1px solid;}
.box h2, .box p, .box table {margin:0;}
.box h2 {padding:2px 10px;cursor:move;}
.box p {padding:10px;}
.box h2 { margin: 0 0 5px;}
.box ul { list-style: none; margin: 5px; padding: 0;}
.box li { list-style: none; margin: 0; padding: 0;}
.box, .box > .utils a {background:$white; border-color:$grey-light}
.utils a {position:absolute;top:5px;right:10px;border:1px solid;padding:4px 8px;}

/* Kill IE */
.killIE {  margin:0 auto 15px; width:92%;}
.killIE .killThem {border-radius:0 0 10px 10px; padding:20px; background:#FF6}
.killIE  p { margin:0.125em 0}
.killIE h2 { font-size:3em; margin-bottom:0; font-weight:bold;}
.killIE a { float:left; display:block; text-align:center; padding:5px;  margin-right:20px}
.killIE a:hover { color:#000; background:#ed3}
.killIE a span { display:block;}
.killIE a em { display:block; font-size:11px; font-style:normal;}
.killIE img { width:150px;}
.killIE a.ie img { width:150px;}


/* Interface */
.listAdminInterface {
  margin: 15px 0;

  ol {

    min-height: 18px;
    border: 1px $grey-light dashed;
    padding: 8px 0 8px 20px;
    margin: 0 0 10px;
    border-left: 5px solid $lighton-color;
    list-style: none;
    counter-reset: item;

    li {
      padding: 3px 30px 3px 3px;
      margin: 3px 5px;
      cursor: url('/admin/i/dragdropSel.png'), move;
      counter-increment: item;
      position: relative;

      &.closed {
        > ol {
          display: none;
        }
      }
      &.ui-sortable-helper {
       height: auto !important;
      }
      &.ui-sortable-placeholder {
        height: 30px !important;
        background: rgba(#000, .125);
        visibility: visible !important;
      }
      > i.toggle {
        font-style: normal;
        top:3px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 20px;
        color: $lighton-color;
      }
      strong {
        color: $grey;
      }
      span.description {
        color: $grey;
        display: block;
        margin: 2px;
        font-size: 0.95em;
        white-space: pre-wrap;
        span {
          display: block;
        }
      }
      > span {
        padding-left: 25px;
        border: 1px solid transparent;
        display: block;
        margin-bottom: 3px;
        &:before {
          content: counters(item, ".") " ";
          display: inline;
        }
      }
      span.nb {
        display: none;
      }

      &.protectedYes {
        cursor: default;
        > span > span.label:after {
          content: '🔒'
        }
        > span .edit {
          width: 30px;
          display: inline-block;
          a {
            display: none;
          }
        }
      }
      a {
        color: $bt;
        background: $bt-bg;
        border-radius: $bt-radius;
        border: $bt-border;
        display: inline-block;
        margin-left: 5px;
        padding: 0 5px;
        &:hover {
          color: $bt-over;
          background: $bt-bg-over;
        }
      }
      .actions {
        display: none;
        .edit a:before {
          content: '✎ ';
        }
        .addInside a:before {
          content: '↴ ';
        }
        .addBefore a:before {
          content: '⇡ ';
        }
        .addAfter a:before {
          content: '⇣ ';
        }
      }
      > span:hover .actions {
        display: inline;
      }
    }
  }

  .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface {
    background: #F9F9F9;
  }
  .listboxInterface .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface,
  .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface .listboxInterface {
    background: #fff;
  }
}
/* Crop */
body.pCrop { background: $crop-bg;}
body.pCrop #content{padding:0; margin: 0; width:auto; background: none;}
.cropInterface {margin:5px 0 0; text-align: center;}
.cropInterface .jcrop-holder {margin: 0 auto;}
.cropInterface .bt,
.fileUpload a.btCrop { display: inline-block;}

@media only screen and (min-width: 1601px) {

}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {

}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {

}
