.stats-content {
  h2 {
    background: #feb734;
    padding: em(5);
    margin-top: em(40);
    margin-bottom: 0;
    color: white;
    font-family: ProximaNova, Helvetica, Arial, sans-serif;
    font-size: em(20);
  }

  .pie-choices {
    width: 100%;
    min-height: 400px;
    margin-bottom: -40px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      width: 100%;
      background: #CCC;
      box-sizing: border-box;
      padding: em(5);
      &:nth-child(2n+1) {
        background-color: rgba(0, 0, 0, 0.0980392);
      ;
      }
    }
  }
}