#mailjet {
  .bar {
    background: rgba(0, 0, 0, 0.1);
    height: 50px;
    position: relative;
    .process {
      background: #97b200;
      height: 50px;
      .info {
        position: absolute;
        width: auto;
        box-sizing: border-box;
        color: white;
        font-size: 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .timer-content {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 30px;
    overflow: hidden;
    .timer {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.2);
      float: left;
    }
  }

  input {
    border-bottom: 1px solid black;
  }
  .item {
    opacity: 0.2;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    transition: background-color 0.5s ease;
    &.done, &.processing {
      opacity: 1;
      color: white;
    }

    &.done {
      background-color: #97b200;
    }

    &.processing {
      background-color: #ff8a00;
    }
  }

  .send {
    button {
      background: #feb734;
      border: none;
      padding: 10px;
      box-sizing: border-box;
      margin: 0 auto;
      width: 20%;
      display: block;
      color: white;
      cursor: pointer;
      &:hover {
        background: #ff8a00;
      }
    }
  }


  h2 {font-size: 2em;}
  table  {
    border:1px solid #999;
  }
  td,
  th {
    border-bottom: 1px solid #999;
    padding: 5px;
  }
  th {
    background: #eaeaea;
  }
  tr:nth-child(2n) th {
    background: #f2f2f2;
  }
  td {
    text-align: right;
  }
  th a {color: inherit; text-decoration: none}
  .messageInfo {
    margin-top: 1em;
  }
  .legend {
    margin-top: 3px;
    font-size: .86em;
  }
  a.bt {
    display: inline-block;
  }

  .loader {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  button.send,button.stop  {
    float: right;
    background-color: #feb734;
    border: none;
    color: white;
    margin-right: 10px;
    padding: 5px;
    &:hover {
      background-color: #ff8a00;
    }
  }
}

.time-picker input.display-time {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: auto !important;
}

.vdp-datepicker {
  display: inline-block;
}

.lds-grid {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #eaeaea;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.pag {
  padding: 0;
  li {
    display: inline-block;
    margin-right: .3%;
    color: #ff8a00;
    cursor: pointer;
    &.active {
      cursor: default;
      color: #000;
    }
  }
}